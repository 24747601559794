import React, { Component } from 'react';
import axios from 'axios';
//import ReportFilterInput from '../../components/reportfilter';
import MailingsSelector from '../../components/mailingsselector';
import FilenameInput from '../../components/filenameinput';
import SubmitButton from '../../components/submitbutton';
import ErrorPanel from '../../components/errorpanel';
import Loading from '../../components/loading';
const fileDownload = require('js-file-download');
const CancelToken = axios.CancelToken;

export class Mailings extends Component {
  static caption = 'Mailings Report';
  static path = '/reports/mailings';
  static isAdmin = false;

  constructor() {
    super();
    this.reportFilter = React.createRef();
    this.selectedMailings = React.createRef();
    this.outputFilename = React.createRef();
    this.state = {
      state: 'Input',
      clients: [],
      error: null
    };
  }

  submitForm() {
    //const reportFilter = this.reportFilter.current;
    const selectedMailings = this.selectedMailings.current;
    const outputFilenameEdit = this.outputFilename.current;
    const client = this.props.client || {};

    const mailings = selectedMailings.getValue();

    if(mailings.length===0){
      return this.setState({error: 'Must select at least one mailing.'});
    }

    const filter = '^'+mailings.map(m=>m.name).join('|')+'$';

    const { privateKey, publicKey, accountId } = client;
    //const filter2 = reportFilter.getValue();
    const outputFilename = outputFilenameEdit.getValue();
    if (!filter) {
      return this.setState({ error: 'Filter is required' });
    }
    this.requestSource = CancelToken.source();
    axios
      .post(
        '/api/v1/reports/mailings',
        {
          privateKey,
          publicKey,
          accountId,
          filter,
          cancelToken: this.requestSource.token
        },
        { responseType: 'blob' }
      )
      .then(response => {
        this.setState({
          error: '',
          state: 'Input'
        });
        return fileDownload(response.data, outputFilename);
      })
      .catch(error => {
        this.setState({
          state: 'Error',
          error
        });
      });
    return this.setState({ error: '', state: 'Requesting' });
  }

  showForm() {
    return (
      <form>
        <ErrorPanel error={this.state.error} />
        <table>
          <tbody>
            <tr>
              <th>Mailings:</th>
              <td>
                <MailingsSelector
                  client={this.props.client}
                  ref={this.selectedMailings} />
              </td>
            </tr>
            {/*
            <tr>
              <th>Filter:</th>
              <td>
                <ReportFilterInput ref={this.reportFilter} />
              </td>
            </tr>
            */}
            <tr>
              <th>Ouput Filename:</th>
              <td>
                <FilenameInput ref={this.outputFilename} default="report.zip" />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <SubmitButton onClick={this.submitForm.bind(this)} />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }

  showRequesting() {
    return <Loading />;
  }

  pageContent() {
    switch (this.state.state) {
      case 'Requesting':
        return this.showRequesting();
      default:
        return this.showForm();
    }
  }

  render() {
    const page = this.pageContent();
    return (
      <div>
        <h1>Mailings Report</h1>
        {page}
      </div>
    );
  }
}

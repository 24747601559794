import React, { Component } from 'react';
import Table from './table';
import Loading from './loading';
import ErrorPanel from './errorpanel';
import axios from 'axios';
const CancelToken = axios.CancelToken;

export class DataTable extends Component {
  constructor() {
    super();
    this.state = { loading: true, data: [], error: null };
  }

  componentWillUnmount() {
    if (this.requestSource) {
      this.requestSource.cancel();
    }
  }

  reformResponse(data) {
    if (typeof this.props.reform === 'function') {
      return this.props.reform(data);
    }
    if (typeof this.props.root === 'string' && data[this.props.root]) {
      return data[this.props.root];
    }
    return data;
  }

  refresh() {
    const { url } = this.props;

    if (!url) {
      setImmediate(() => {
        const err = new Error(`URL is a required parameter.`);
        return this.setState({ loading: false, error: err });
      });
    }

    this.setState({ loading: true, data: [], error: null });

    this.requestSource = CancelToken.source();

    axios
      .get(url, { cancelToken: this.requestSource.token })
      .then((response) => {
        const data = this.reformResponse(response.data);
        if (response.status === 200) {
          return this.setState({
            data,
            error: null,
            loading: false,
          });
        }
        return this.setState({
          error: data,
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        this.setState({ error, loading: false });
      });
  }

  componentDidMount() {
    this.refresh();
  }

  renderLoading() {
    return <Loading />;
  }

  renderError() {
    const { error } = this.state;
    return <ErrorPanel error={error} />;
  }

  renderTable() {
    const { columns, actions } = this.props;
    const { data } = this.state;
    return <Table columns={columns} data={data} actions={actions} />;
  }

  render() {
    if (this.state.loading) {
      return this.renderLoading();
    }
    if (this.state.error) {
      return this.renderError();
    }
    return this.renderTable();
  }
}

export default DataTable;

import React, { Component } from 'react';
import './table.css';

const upperFirst = s => s.charAt(0).toUpperCase() + s.slice(1);

const betterType = o => {
  const type = typeof o;
  if (type === 'object') {
    if (Array.isArray(o)) {
      return 'array';
    }
    if (o instanceof RegExp) {
      return 'regex';
    }
    if (o instanceof Date) {
      return 'date';
    }
    if (o === null) {
      return 'null';
    }
    return type;
  }
  return type;
};

const typeCheckers = {
  object(o1, o2) {
    const keys1 = Object.keys(o1);
    const keys2 = Object.keys(o2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    const keysSame = keys1.filter(key => keys2.indexOf(key) > -1);
    if (keysSame.length !== keys1.length) {
      return false;
    }
    return keys1.every(key => {
      // eslint-disable-next-line
      return isTheSame(o1[key], o2[key]);
    });
  },
  array(a1, a2) {
    if (a1.length !== a2.length) {
      return false;
    }
    return a1.every(index => {
      // eslint-disable-next-line
      return isTheSame(a1[index], a2[index]);
    });
  },
  date(d1, d2) {
    return d1.getTime() === d2.getTime();
  },
  default(a, b) {
    return a === b;
  }
};

const isTheSame = (o1, o2) => {
  const type = betterType(o1);
  if (type !== betterType(o2)) {
    return false;
  }
  const checker = typeCheckers[type] || typeCheckers.default;
  return checker(o1, o2);
};

const SPECIAL_CASE = [
  { key: '_ID', value: 'ID' },
  'ID',
  'HTTP',
  'HTTPS',
  'URI',
  'URL',
  'API',
  { key: 'URIS', value: "URI's" },
  { key: 'URLS', value: "URL's" }
];

const upperIt = s => {
  const su = s.toUpperCase();
  return SPECIAL_CASE.reduce((res, map) => {
    if (map === su) {
      return map;
    }
    if (map.key === su) {
      return map.value;
    }
    return res;
  }, upperFirst(s))
    .replace(/([a-z])([A-Z])/g, (match, first, second) => {
      return `${first} ${second}`;
    })
    .replace(/([a-z])_([a-z])/gi, (match, first, second) => {
      return `${first} ${second}`;
    });
};

const makeCaption = src =>
  src
    .replace(/_/g, ' ')
    .replace(/[ \t]+/, ' ')
    .trim()
    .split(' ')
    .map(upperIt)
    .join(' ');

const getHeader = (column, index) => {
  const caption = column.Header || column.header || column.caption;
  if (caption) {
    return caption;
  }
  const value = column.value;
  if (value) {
    return makeCaption(value);
  }
  return index.toString();
};

const TableHead = ({ columns = [], actions } = {}) => {
  const cols = columns.map((c, i) => {
    return <th key={`${i}_${c.key}`}>{c.Header}</th>;
  });
  if (actions) {
    cols.push(<th key="actions" />);
  }
  return (
    <thead>
      <tr>{cols}</tr>
    </thead>
  );
};

const TableRow = ({ columns, data, actions } = {}) => {
  const cells = columns
    .map(f => (f(data) || '').toString())
    .map((d, i) => <td key={i}>{d}</td>);
  if (actions) {
    cells.push(<td key="actions">{actions(data)}</td>);
  }
  return <tr>{cells}</tr>;
};

const TableRows = ({
  columns: columnConfig = [],
  rows: data = [],
  actions
} = {}) => {
  const columns = columnConfig.map(c => {
    const accessor = c.accessor || c.key || c.Header;
    if (typeof accessor === 'function') {
      return accessor;
    }
    return row => row[accessor];
  });
  const rows = data.map((r, i) => (
    <TableRow key={i} columns={columns} data={r} actions={actions} />
  ));
  return <tbody>{rows}</tbody>;
};

class TableComponent extends Component {
  getTable() {
    return (
      <table>
        <TableHead columns={this.props.columns} actions={this.props.actions} />
        <TableRows
          columns={this.props.columns}
          rows={this.props.data}
          actions={this.props.actions}
        />
      </table>
    );
  }

  render() {
    return this.getTable();
  }
}

export class Table extends Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data };
  }

  /*
  componentWillReceiveProps(newProps) {
    if (newProps.data && !isTheSame(this.state.data, newProps.data)) {
      this.setState({ data: newProps.data });
    }
  }
  */

  componentDidUpdate(prevProps){
    const newProps = this.props;
    if (newProps.data && !isTheSame(this.state.data, newProps.data)) {
      this.setState({ data: newProps.data });
    }
  }

  getData() {
    const { data: raw } = this.state;
    if (!raw) {
      return [];
    }
    const data = this.props.mapRoot ? raw[this.props.mapRoot] : raw;
    if (!data) {
      return [];
    }
    if (typeof this.props.mapper === 'function') {
      if (Array.isArray(data)) {
        return data.map(this.props.mapper);
      }
      const mappedData = this.props.mapper(data);
      if (!mappedData) {
        return [];
      }
      return mappedData;
    }
    return Array.isArray(data) ? data : [];
  }

  getFilterValue(from, data) {
    const type = typeof from;
    if (type === 'string') {
      return from;
    }

    if (type === 'number') {
      return String(from);
    }
    if (type === 'boolean') {
      return from ? 'True' : 'False';
    }
    if (type === 'object') {
      if (from instanceof Date) {
        return from.toISOString();
      }
      if (React.isValidElement(from)) {
        return String(from);
      }
    }
    if (type === 'function') {
      return from(data);
    }
    return JSON.stringify(from, null, 2);
  }

  getColumns(data, suppress) {
    const columns = this.props.columns
      ? this.props.columns.map((c, i) => {
          if (typeof c === 'string') {
            return {
              key: c,
              accessor: c,
              Cell({ original: data }) {
                return this.getDisplayValue(data[c], data);
              },
              Header: makeCaption(c)
            };
          }
          return {
            ...c,
            Cell({ original: data }) {
              return this.getDisplayValue(data[c.value], data);
            },
            Header: getHeader(c, i)
          };
        })
      : data.reduce((headers, rec) => {
          return Object.keys(rec).reduce((headers, header) => {
            if (suppress.indexOf(header) > -1) {
              return headers;
            }
            if (headers.findIndex(h => h.key === header) > -1) {
              return headers;
            }
            return headers.concat({
              key: header,
              accessor: header,
              Cell({ original: data }) {
                return this.getDisplayValue(data[header], data);
              },
              Header: makeCaption(header)
            });
          }, headers);
        }, []);
    return columns;
  }

  getTable() {
    const actions = this.props.actions;
    const data = this.getData();
    const suppress = Array.isArray(this.props.suppress)
      ? this.props.suppress
      : [];
    const columns = this.getColumns(data, suppress);
    const defaultFilterMethod = (filter, row, column) => {
      const id = filter.pivotId || filter.id;
      return row[id] !== undefined
        ? this.getFilterValue(row[id], row)
            .toLowerCase()
            .indexOf(filter.value.toLowerCase()) !== -1
        : true;
    };
    const {
      filterable = true,
      showPagination = true,
      showPaginationBottom = true,
      showPaginationTop = false
    } = this.props;
    return (
      <TableComponent
        {...this.props}
        className="-striped -highlight"
        minRows={0}
        filterable={filterable}
        data={data}
        actions={actions}
        columns={columns}
        defaultFilterMethod={defaultFilterMethod}
        showPagination={showPagination}
        showPaginationTop={showPaginationTop}
        showPaginationBottom={showPaginationBottom}
      />
    );
  }

  render() {
    return this.getTable();
  }
}

export default Table;

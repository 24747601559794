import React, { Component } from 'react';
import axios from 'axios';
import FileUploadInput from '../../components/fileupload';
import FilenameInput from '../../components/filenameinput';
import TextInput from '../../components/textinput';
import SubmitButton from '../../components/submitbutton';
import ErrorPanel from '../../components/errorpanel';
import Loading from '../../components/loading';
const fileDownload = require('js-file-download');

export class Scrubber extends Component {
  static caption = 'Scrub Lists';
  static path = '/lists/scrub';
  static isAdmin = true;

  constructor() {
    super();
    this.listInput = React.createRef();
    this.scrubInput = React.createRef();
    this.scrubFieldInput = React.createRef();
    this.outputFilename = React.createRef();
    this.state = {
      state: 'Input',
      error: ''
    };
  }

  submitForm() {
    const outputFilenameEdit = this.outputFilename.current;
    const outputFilename = outputFilenameEdit.getValue();
    const formData = new FormData();
    Array.from(document.querySelectorAll('[type=file]')).forEach(input => {
      const name = input.name;
      const files = input.files;
      for (let i = 0; i < files.length; i++) {
        formData.append(`${name}`, files[i]);
      }
    });

    const target = '/api/v1/lists/scrub';

    axios
      .post(target, formData, {
        responseType: 'blob'
      })
      .then(response => {
        this.setState({
          error: '',
          state: 'Input'
        });
        return fileDownload(response.data, outputFilename);
      })
      .catch(error => {
        this.setState({
          state: 'Error',
          error: error.toString()
        });
      });
    return this.setState({ error: '', state: 'Requesting' });
  }

  showForm() {
    return (
      <form>
        <h1>List Scrubber</h1>
        <ErrorPanel error={this.state.error} />
        <table>
          <tbody>
            <tr>
              <th>Lists:</th>
              <td>
                <FileUploadInput
                  name="list"
                  multiple={false}
                  ref={this.listInput}
                />
              </td>
            </tr>
            <tr>
              <th>Scrub Emails List:</th>
              <td>
                <FileUploadInput
                  name="scrub"
                  multiple={false}
                  ref={this.scrubInput}
                />
              </td>
            </tr>
            <tr>
              <th>Scrub Fieldname:</th>
              <td>
                <TextInput ref={this.scrubFieldInput} default="e-?mail" />
              </td>
            </tr>
            <tr>
              <th>Ouput Filename:</th>
              <td>
                <FilenameInput
                  ref={this.outputFilename}
                  default="scrubbed.csv"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <SubmitButton onClick={this.submitForm.bind(this)} />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }

  showRequesting() {
    return <Loading />;
  }

  render() {
    switch (this.state.state) {
      case 'Requesting':
        return this.showRequesting();
      default:
        return this.showForm();
    }
  }
}

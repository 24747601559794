import React, { Component } from 'react';
import './button.css';

export class LinkButton extends Component {
  render() {
    const { href } = this.props;
    return (
      <a href={href} className="button">
        {this.props.children}
      </a>
    );
  }
}

export default LinkButton;

import React, { Component } from 'react';
import axios from 'axios';
import FilenameInput from '../../components/filenameinput';
import FileUploadInput from '../../components/fileupload';
import SubmitButton from '../../components/submitbutton';
import ErrorPanel from '../../components/errorpanel';
import Loading from '../../components/loading';
import Select from '../../components/select';
const fileDownload = require('js-file-download');
const CancelToken = axios.CancelToken;

const getData = (data, callback) => {
  if (data instanceof Blob) {
    const fr = new FileReader();
    fr.onload = function () {
      return callback(null, this.result);
    };
    return fr.readAsText(data);
  }
  return callback(null, data);
};

export class FuzzyMatch extends Component {
  static caption = 'Fuzzy Match';
  static path = '/reports/fuzzy';
  static isAdmin = true;

  constructor() {
    super();
    this.outputFilename = React.createRef();
    this.stewartList = React.createRef();
    this.clientList = React.createRef();
    this.trainingSet = React.createRef();
    this.matchMode = React.createRef();
    this.state = {
      state: 'Input',
      clients: [],
      error: null,
    };
  }

  submitForm() {
    const outputFilenameEdit = this.outputFilename.current;
    const matchModeEdit = this.matchMode.current;

    const outputFilename = outputFilenameEdit.getValue();
    const matchMode = matchModeEdit.getValue();

    const client = this.props.client || {};
    console.log('Client: ', client);
    const { accountId } = client;

    const formData = new FormData();
    formData.append('mode', matchMode);
    formData.append('accountId', accountId);

    if (outputFilename) {
      formData.append('outputFilename', outputFilename);
    }
    Array.from(document.querySelectorAll('[type=file]')).forEach((input) => {
      const name = input.name;
      const files = input.files;
      for (let i = 0; i < files.length; i++) {
        formData.append(`${name}`, files[i]);
      }
    });

    this.requestSource = CancelToken.source();
    axios
      .post('/api/v1/match/fuzzy', formData, {
        responseType: 'blob',
        cancelToken: this.requestSource.token,
      })
      .then((response) => {
        this.setState({
          error: '',
          state: 'Input',
        });
        getData(response.data, (err, contents) => {
          if (err) {
            return this.setState({ error: err });
          }
          return fileDownload(contents, outputFilename);
        });
      })
      .catch((error) => {
        this.setState({
          state: 'Error',
          error,
        });
      });
    return this.setState({ error: '', state: 'Requesting' });
  }

  showForm() {
    const matchModes = [
      { value: 'same', label: 'Same' },
      { value: 'unique', label: 'Unique' },
    ];
    return (
      <form>
        <ErrorPanel error={this.state.error} />
        <table>
          <tbody>
            <tr>
              <th>Client List:</th>
              <td>
                <FileUploadInput
                  name="clientList"
                  multiple={false}
                  ref={this.clientList}
                />
              </td>
            </tr>
            <tr>
              <th>Stewart List:</th>
              <td>
                <FileUploadInput
                  name="list"
                  multiple={false}
                  ref={this.stewartList}
                />
              </td>
            </tr>
            <tr>
              <th>Training Set:</th>
              <td>
                <FileUploadInput
                  name="trainingFile"
                  multiple={false}
                  ref={this.trainingSet}
                  accept=".json"
                />
              </td>
            </tr>
            <tr>
              <th>Match Mode:</th>
              <td>
                <Select
                  name="mode"
                  items={matchModes}
                  defaultValue="same"
                  ref={this.matchMode}
                />
              </td>
            </tr>
            <tr>
              <th>Ouput Filename:</th>
              <td>
                <FilenameInput
                  ref={this.outputFilename}
                  default="matches.csv"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <SubmitButton onClick={this.submitForm.bind(this)} />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }

  showRequesting() {
    return <Loading />;
  }

  pageContent() {
    switch (this.state.state) {
      case 'Requesting':
        return this.showRequesting();
      default:
        return this.showForm();
    }
  }

  render() {
    const page = this.pageContent();
    return (
      <div>
        <h1>Fuzzy Match Report</h1>
        {page}
      </div>
    );
  }
}

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Header } from './header';
import { Footer } from './footer';
import { Pages } from './pages';
import './layout.css';

export const Layout = props => (
  <Router>
    <div className="content">
      <Header {...props} />
      <Pages {...props} />
    </div>
    <Footer {...props} />
  </Router>
);

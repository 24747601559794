import React from 'react';
import { Login as LoginPage } from './pages/login';
import { pages } from './pages';
import { Layout } from './components/layout';
import { Loading } from './components/loading';

import axios from 'axios';

import './App.css';

const canSeePage = (page, isAdmin)=>{
  if(isAdmin){
    return true;
  }
  if(page.isAdmin){
    return false;
  }
  return true;
}

const getUserPages = (user)=>{
  if(!user){
    return [];
  }
  const groups = user.groups;
  const isAdmin = groups.filter(group=>group.toLowerCase()==='systems/admin').length > 0;
  return pages.filter(page=>canSeePage(page, isAdmin));
}

class SecureApp extends React.Component {
  renderLoading() {
    return <Loading />;
  }

  renderLogin() {
    const LoginComponent = this.props.LoginComponent;
    return <LoginComponent {...this.props} />;
  }

  renderPage() {
    const LayoutComponent = this.props.LayoutComponent;
    return <LayoutComponent {...this.props} />;
  }

  render() {
    const { user, loginCheckDone } = this.props;
    if (!loginCheckDone) {
      return this.renderLoading();
    }
    if (user) {
      return this.renderPage();
    }
    return this.renderLogin();
  }
}

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      loggedIn: false,
      user: null,
      loginCheckDone: false,
      client: null
    };
    this._logout = this._logout.bind(this);
    this._login = this._login.bind(this);
  }

  componentDidMount() {
    axios.get(`/auth/user`).then(response => {
      if (!!response.data.user) {
        return this.setState({
          loggedIn: true,
          user: response.data.user,
          loginCheckDone: true
        });
      }
      this.setState({
        loggedIn: false,
        user: null,
        loginCheckDone: true
      });
    });
  }

  _logout(event) {
    event.preventDefault();
    axios.post(`/auth/logout`).then(response => {
      if (response.status === 200) {
        this.setState({
          loggedIn: false,
          user: null
        });
      }
    });
  }

  _login(username, password) {
    axios
      .post(`/auth/login`, {
        username,
        password
      })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            loggedIn: true,
            user: response.data.user
          });
        }
      });
  }

  clientSelected(selector) {
    const client = selector.getValue();
    this.setState({ client });
  }

  render() {
    const { user, loginCheckDone } = this.state;
    const userPages = getUserPages(user)
    return (
      <SecureApp
        loginCheckDone={loginCheckDone}
        user={user}
        LoginComponent={LoginPage}
        LayoutComponent={Layout}
        loginHandler={this._login.bind(this)}
        logoutHandler={this._logout.bind(this)}
        pages={userPages}
        client={this.state.client}
        onClientSelected={this.clientSelected.bind(this)}
      />
    );
  }
}

export default App;

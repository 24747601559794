import React, { Component } from 'react';

export class FilenameInput extends Component {
  constructor() {
    super();
    this.edit = React.createRef();
  }
  getValue() {
    return this.edit.current.value || this.props.default || '';
  }
  render() {
    return (
      <input
        ref={this.edit}
        type="text"
        placeholder={'EG: ' + (this.props.default || 'somefile.zip')}
      />
    );
  }
}

export default FilenameInput;

import React, { Component } from 'react';
import ErrorPanel from './errorpanel';
import axios from 'axios';
import Select from 'react-select';
const CancelToken = axios.CancelToken;

const getClientShort = v => {
  if (typeof v === 'string') {
    return v.toUpperCase();
  }
  if (v && typeof v.value === 'string') {
    return v.value.toUpperCase();
  }
};

export class ClientSelector extends Component {
  constructor(props) {
    super();
    this.select = React.createRef();
    this.state = {
      clients: [],
      loading: true,
      client: props.defaultValue
    };
  }

  componentWillUnmount() {
    if (this.requestSource) {
      this.requestSource.cancel();
    }
  }

  componentDidMount() {
    this.requestSource = CancelToken.source();
    axios
      .get('/api/v1/clients', { cancelToken: this.requestSource.token })
      .then(response => {
        if (response.status === 200) {
          const clients = response.data;
          const { short, name } = clients[0] || {};
          const client = this.state.client
            ? this.state.client
            : { value: short, label: name };
          return this.setState({
            clients,
            client,
            error: null,
            loading: false
          });
        }
      })
      .catch(error => {
        this.setState({
          error: error.toString(),
          loading: false
        });
      });
  }

  getValue() {
    if (!this.state.client) {
      return false;
    }
    const val = getClientShort(this.state.client);
    const client = this.state.clients
      .filter(c => c.short.toUpperCase() === val)
      .shift();
    return client;
  }

  renderLoading() {
    return '';
  }

  onChange(client) {
    this.setState({ client });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loading && !this.state.loading) {
      if (typeof this.props.onLoaded === 'function') {
        return this.props.onLoaded(this);
      }
    }
    if (prevState.client !== this.state.client) {
      if (this.props.onChange) {
        return this.props.onChange(this);
      }
    }
  }

  renderSelect() {
    const { clients = [] } = this.state;
    const options = clients.map(({ short, name }) => ({
      value: short.toUpperCase(),
      label: name
    }));
    const pdv = getClientShort(this.props.defaultValue);
    const defaultValue =
      options.filter(({ value }) => value === pdv).shift() || options[0];
    return (
      <Select
        defaultValue={defaultValue}
        ref={this.select}
        onChange={this.onChange.bind(this)}
        options={options}
      />
    );
  }

  renderError() {
    return <ErrorPanel error={this.state.error} />;
  }

  render() {
    if (this.state.error) {
      return this.renderError();
    }
    if (this.state.clients && this.state.clients.length) {
      return this.renderSelect();
    }
    return this.renderLoading();
  }
}

export default ClientSelector;

import React, { Component } from 'react';
import ErrorPanel from '../components/errorpanel';
import axios from 'axios';
import { TextArea } from '../components/textarea';
import SubmitButton from '../components/submitbutton';
import Loading from '../components/loading';

const CancelToken = axios.CancelToken;

const getErrorMessage = error => {
  if (error.response) {
    if (error.response.data) {
      /*
      if(error.response.data.errmsg){
        return error.response.data.errmsg;
      }
      //*/
      return error.response.data;
    }
  }
  return error.errmsg || error.toString();
};

const isValidJSONValue = value => {
  try {
    return JSON.parse(value);
  } catch (e) {
    try {
      // eslint-disable-next-line
      const f = new Function('', `return ${value}`);
      return f() || true;
    } catch (e) {
      return false;
    }
  }
};

export class SystemConfig extends Component {
  static caption = 'System Configuration';
  static path = '/system/configuration';
  static isAdmin = true;

  constructor() {
    super();
    this.state = { loading: true };
    this.configEdit = React.createRef();
  }

  getConfig() {
    this.requestSource = CancelToken.source();
    axios
      .get(`/api/v1/system/config`, { cancelToken: this.requestSource.token })
      .then(response => {
        if (response.status === 200) {
          const config = response.data;
          return this.setState({
            config,
            error: null,
            loading: false
          });
        }
        this.setState({
          error: response.data,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          error: getErrorMessage(error),
          loading: false
        });
      });
  }

  componentDidMount() {
    this.getConfig();
  }

  renderLoading() {
    return <Loading />;
  }

  submitForm() {
    const configSource = this.configEdit.current.getValue();
    const config = isValidJSONValue(configSource);
    if (!config) {
      return this.setState({ error: 'Ensure configuration is valid' });
    }

    this.requestSource = CancelToken.source();
    axios
      .put(`/api/v1/system/config`, config, {
        cancelToken: this.requestSource.token
      })
      .then(response => {
        if (response.status === 200) {
          const config = response.data;
          return this.setState({
            config,
            error: null,
            loading: false
          });
        }
        this.setState({
          error: response.data,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          error: getErrorMessage(error),
          loading: false
        });
      });
  }

  render() {
    if (this.state.loading) {
      return this.renderLoading();
    }
    return (
      <form>
        <h1>System Configuration</h1>
        <ErrorPanel error={this.state.error} />
        <TextArea
          ref={this.configEdit}
          style={{ width: '100%', height: '10em' }}
          defaultValue={JSON.stringify(this.state.config, null, 2)}
        />
        <SubmitButton onClick={this.submitForm.bind(this)} />
      </form>
    );
  }
}

import React, { Component } from 'react';
import DataTable from '../../components/datatable';
import LinkButton from '../../components/linkbutton';
import DeleteButton from '../../components/deletebutton';
import ErrorPanel from '../../components/errorpanel';
import axios from 'axios';
const CancelToken = axios.CancelToken;

export class Clients extends Component {
  static caption = 'Clients';
  static path = '/clients';
  static isAdmin = true;

  constructor() {
    super();
    this.state = {};
    this.datatable = React.createRef();
  }

  refreshView() {
    this.datatable.current.refresh();
  }

  deleteClient({ clientId }) {
    const url = `/api/v1/clients/${clientId}`;

    this.requestSource = CancelToken.source();

    axios
      .delete(url, { cancelToken: this.requestSource.token })
      .then((response) => {
        const { data } = response.data;
        if (response.status === 200) {
          this.setState({ error: undefined });
          return this.refreshView();
        }
        return this.setState({
          error: data,
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        this.setState({ error, loading: false });
      });
  }

  render() {
    return (
      <div>
        <h1>Clients</h1>

        <ErrorPanel error={this.state.error} />
        <DataTable
          ref={this.datatable}
          url="/api/v1/clients"
          columns={['name', 'short']}
          actions={(client) => (
            <div>
              <LinkButton href={`/client/${client._id}`}>Edit</LinkButton>
              <DeleteButton
                onDelete={() => this.deleteClient({ clientId: client._id })}
              >
                Delete
              </DeleteButton>
            </div>
          )}
        />
        <div
          style={{
            padding: '5px 4px',
            borderBottom: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <LinkButton href={`/client/new`}>New</LinkButton>
        </div>
      </div>
    );
  }
}

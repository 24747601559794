import { Home } from './home';
//import { HomeCampaignMonitor as Home } from './home_campaignmonitor';
//import { HomeIndividualClient } from './home_individual_client';
import { Merger, Scrubber } from './lists';
import {
  GeoFence,
  Mailings,
  MailingOverview,
  Matches,
  FuzzyMatch,
} from './reports';
import { User, Users } from './users/index';
import { Clients, Client, Subscriptions } from './clients';
import { Prototype } from './prototype';
import { Listing } from './files';
import { SystemConfig } from './systemconfig';

export const pages = [
  Home,
  //Home14Days,
  //HomeIndividualClient,
  Listing,
  Clients,
  Client,
  Merger,
  Scrubber,
  GeoFence,
  Matches,
  FuzzyMatch,
  Mailings,
  MailingOverview,
  Users,
  User,
  Prototype,
  Subscriptions,
  SystemConfig,
];

import React, { Component } from 'react';

export class TextArea extends Component {
  constructor() {
    super();
    this.edit = React.createRef();
  }
  getValue() {
    return this.edit.current.value || this.props.default || '';
  }
  render() {
    return (
      <textarea
        ref={this.edit}
        style={this.props.style}
        type="text"
        placeholder={this.props.default || ''}
        defaultValue={this.props.defaultValue}
      />
    );
  }
}

export default TextArea;

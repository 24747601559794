import React from 'react';
import './errorpanel.css';

const getErrorString = err => {
  if (typeof err === 'string') {
    return err;
  }
  if(typeof err === 'object'){
    if (err instanceof Error) {
      return err.toString();
    }

    if (err.errmsg){
      return err.errmsg;
    }

    return JSON.stringify(err);
  }
  return err.toString();
};

export const ErrorPanel = ({ error: err = false, children }) => {
  if (err) {
    const error = getErrorString(err);
    return <div className="error">{error}</div>;
  }
  if (children) {
    return children;
  }
  return <div className="error hidden" />;
};

export default ErrorPanel;

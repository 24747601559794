import collect from 'collect.js';

/**
 * Creates an array of items from an Object
 *
 * Given:
 *  const test = {
 *    Item1: {value: 'item 1'},
 *    Item2: {value: 'item 2'}
 *  }
 *
 * Called as:
 *  collect(test).dictionaryToMap('name')
 *
 * Creates:
 *  [
 *    {
 *      name: 'Item1',
 *      value: 'item 1'
 *    },
 *    {
 *      name: 'Item2',
 *      value: 'item 2'
 *    }
 *  ]
 *
 */

collect().macro('dictionaryToMap', function(as = 'key') {
  return collect(
    this.map((v, name) => Object.assign({ [as]: name }, v)).reduce((a, b) => {
      return a.concat(b);
    }, [])
  );
});

collect().macro('instancesBy', function(
  field,
  { uniqueKey = 'unique', totalKey = 'total' } = {}
) {
  return this.map(elem => ({
    [totalKey]: elem.count(),
    [uniqueKey]: elem.unique(field).count()
  }));
});

collect().macro('populationStandardDeviation', function(field) {
  const values = field ? this.pluck(field) : this;
  const average = values.average();
  const diffs = values.map(value => value - average);
  const squareDiffs = diffs.map(diff => diff * diff);
  const variance = squareDiffs.average();
  return Math.sqrt(variance);
});

collect().macro('populationStandardError', function(field) {
  const values = field ? this.pluck(field) : this;
  const standardDeviation = values.populationStandardDeviation();
  return standardDeviation / Math.sqrt(values.count());
});

collect().macro('sampleStandardDeviation', function(field) {
  const values = field ? this.pluck(field) : this;
  const average = values.average();
  const diffs = values.map(value => value - average);
  const squareDiffs = diffs.map(diff => diff * diff);
  const variance = squareDiffs.sum() / (values.count() - 1);
  return Math.sqrt(variance);
});

collect().macro('sampleStandardError', function(field) {
  const values = field ? this.pluck(field) : this;
  const standardDeviation = values.sampleStandardDeviation();
  return standardDeviation / Math.sqrt(values.count());
});

collect().macro('standardDeviation', function(type = 'population') {
  const m = `${type.toLowerCase()}StandardDeviation`;
  return this[m]();
});

collect().macro('range', function(field) {
  const values = field ? this.pluck(field) : this;
  const sorted = values.sort();
  const first = sorted.first();
  const last = sorted.last();
  return last - first;
});

export default collect;

import React, { Component, useLayoutEffect, useState, useRef } from 'react';

import MailingSelector from '../../components/mailingselector';
import Button from '../../components/button';
import SubmitButton from '../../components/submitbutton';
import Loading from '../../components/loading';
import Table from '../../components/table';
import queryString from 'query-string';

import zipcodes from './zipcodemap';
import Map from 'pigeon-maps';

import turfBbox from '@turf/bbox';
import {
  featureCollection as turfFeatureCollection,
  point as turfPoint
} from '@turf/helpers';
import geoViewport from '@mapbox/geo-viewport';

import ReactChartkick, {
  LineChart,
  ColumnChart,
  PieChart
} from 'react-chartkick';
import Chart from 'chart.js';

import axios from 'axios';

ReactChartkick.addAdapter(Chart);

const upperFirst = s => s.charAt(0).toUpperCase() + s.slice(1);
const fileDownload = require('js-file-download');
const stringify = require('csv-stringify');

const SPECIAL_CASE = [
  'ID',
  'HTTP',
  'HTTPS',
  'URI',
  'URL',
  'API',
  { key: 'URIS', value: "URI's" },
  { key: 'URLS', value: "URL's" }
];

const upperIt = s => {
  const su = s.toUpperCase();
  return SPECIAL_CASE.reduce((res, map) => {
    if (map === su) {
      return map;
    }
    if (map.key === su) {
      return map.value;
    }
    return res;
  }, upperFirst(s))
    .replace(/([a-z])([A-Z])/g, (match, first, second) => {
      return `${first} ${second}`;
    })
    .replace(/([a-z])_([a-z])/gi, (match, first, second) => {
      return `${first} ${second}`;
    });
};

const makeCaption = src =>
  src
    .replace(/_/g, ' ')
    .replace(/[ \t]+/, ' ')
    .trim()
    .split(' ')
    .map(upperIt)
    .join(' ');

const heatMapColor = value => {
  const h = (1.0 - value) * 240;
  return 'hsla(' + h + ', 100%, 50%, 0.7)';
};

class Marker extends Component {
  constructor() {
    super();
    this.state = { popover: false };
  }

  getPopover() {
    if (!this.state.popover) {
      return '';
    }
    return this.props.children;
  }

  mouseEnter() {
    this.setState({ popover: true });
  }

  mouseLeave() {
    this.setState({ popover: false });
  }

  render() {
    const { left, top, intensity } = this.props;
    const popover = this.getPopover();
    return (
      <div style={{ position: 'absolute', left, top }}>
        <div
          style={{
            transform: 'translate(-50%,-50%)',
            left: '50%',
            top: '0%',
            position: 'absolute'
          }}
        >
          <div
            className="marker map-marker"
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: heatMapColor(intensity)
            }}
            onMouseEnter={this.mouseEnter.bind(this)}
            onMouseLeave={this.mouseLeave.bind(this)}
          >
            {popover}
          </div>
        </div>
      </div>
    );
  }
}

const MapView = ({ zip }) => {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const ratio = 6 / 8;
  const { width, height: currHeight } = dimensions;
  const height = Math.max(currHeight, width * ratio);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, []);

  const data = Object.values(zip || {}).map(
    ({ name, emails, metrics, ...markers }) => {
      return {
        zipcode: `${name}`.padStart(5, '0'),
        markers: Object.entries(markers)
      };
    }
  );

  const latlongs = data
    .map(({ zipcode, markers }) => {
      if (!zipcodes[zipcode]) {
        return console.error(`Invalid zip ${zipcode}`);
      }
      const { lat, long } = zipcodes[zipcode];
      const metric =
        markers.filter(([key]) => key === 'total_clicks').shift() || [];
      return [lat, long, metric[1]];
    })
    .filter(zip => !!zip);

  const max = latlongs.reduce(
    (max, [_a, _b, value]) => (value > max ? value : max),
    0
  );

  const markers = data.map(({ zipcode, markers }) => {
    if (!zipcodes[zipcode]) {
      return console.error('Invalid zip', zipcode);
    }
    const { lat, long } = zipcodes[zipcode];
    const position = [lat, long];

    const rows = markers.map(([key, value]) => (
      <tr key={key}>
        <td>{makeCaption(key)}</td>
        <td>{value}</td>
      </tr>
    ));

    const value = (markers
      .filter(([key]) => key === 'total_clicks')
      .shift() || ['', 0])[1];

    const intensity = value ? value / max : 0;

    return (
      <Marker key={zipcode} anchor={position} intensity={intensity}>
        <div className="tooltip">
          {zipcode}
          <table>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </Marker>
    );
  });

  const centerZoomFromLocations = locations => {
    const points = locations.map(location =>
      turfPoint([location[1], location[0]])
    );
    const features = turfFeatureCollection(points);
    const bounds = turfBbox(features);

    const { center, zoom } = geoViewport.viewport(bounds, [width, height]);

    return {
      center: [center[1], center[0]],
      zoom: Math.min(zoom, 13)
    };
  };

  const loc = centerZoomFromLocations(latlongs);

  return (
    <div ref={targetRef}>
      <Map
        zoomSnap={false}
        metaWheelZoom={false}
        center={loc.center}
        zoom={loc.zoom || 13}
        height={height}
      >
        {markers}
      </Map>
    </div>
  );
};

const metricColumns = ['name', 'total_clicks', 'unique_clicks'];

const LinksMetricsView = ({ title, data }) => {
  if (!data) {
    return;
  }
  const links = Object.values(data || {});

  const linksLines = links.map(({ name, unique_clicks, total_clicks }) => {
    return {
      name,
      data: { 'Unique Clicks': unique_clicks, 'Total Clicks': total_clicks }
    };
  });

  const totalLines = links.reduce((clicks, { name, total_clicks }) => {
    return {
      name: 'Total Clicks',
      data: Object.assign(clicks.data || {}, { [name]: total_clicks })
    };
  }, {});

  const uniqueLines = links.reduce((clicks, { name, unique_clicks }) => {
    return {
      name: 'Unique Clicks',
      data: Object.assign(clicks.data || {}, { [name]: unique_clicks })
    };
  }, {});

  return (
    <div>
      <h2>{title}</h2>
      <table>
        <tbody>
          <tr>
            <td width="50%">
              <ColumnChart data={[totalLines, uniqueLines]} download={true} />
            </td>
            <td width="50%">
              <ColumnChart data={linksLines} download={true} />
            </td>
          </tr>
        </tbody>
      </table>
      <Table columns={metricColumns} data={links} />
    </div>
  );
};

const StateMetricsView = ({ state, zip, title }) => {
  const values = Object.values(state || {});

  const pieSections = values.reduce(
    (sections, { name, total_clicks }) =>
      Object.assign(sections, { [name]: total_clicks }),
    {}
  );

  return (
    <div>
      <h2>{title}</h2>
      <table>
        <tbody>
          <tr>
            <td width="50%">
              <PieChart data={pieSections} download={true} />
            </td>
            <td width="50%">
              <MapView zip={zip} />
            </td>
          </tr>
        </tbody>
      </table>
      <Table columns={metricColumns} data={values} />
    </div>
  );
};

const ClicksByDate = ({ data, title }) => {
  const incPoint = (data, name, date) => {
    const item = (data[name] = data[name] || { name, data: {} });
    item.data[date] = (item.data[date] || 0) + 1;
  };
  const totalData = Object.values(
    data.reduce((data, { date }) => {
      incPoint(data, 'Total', date);
      return data;
    }, {})
  );
  const lineData = Object.values(
    data.reduce((data, { date, link_name }) => {
      incPoint(data, link_name, date);
      return data;
    }, {})
  );
  return (
    <div>
      <h2>{title}</h2>
      <table>
        <tbody>
          <tr>
            <td width="50%">
              <LineChart data={totalData} />
            </td>
            <td width="50%">
              <ColumnChart data={lineData} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export class MailingOverview extends Component {
  static caption = 'Mailing Overview Report';
  static path = '/reports/mailing/overview';

  constructor(props) {
    const params = queryString.parse(props.location.search);
    const { mailing } = params;
    super();
    this.mailingSelector = React.createRef();
    this.state = {
      error: null,
      loading: true,
      defaultMailing: mailing
    };
  }

  mailingSelected() {
    const mailing = this.mailingSelector.current.getValue();
    this.setState({
      mailing,
      data: [],
      loading: false
    });
  }

  submitForm() {
    const { client } = this.props;
    const mailing = this.mailingSelector.current.getValue();
    if (!client || !mailing) {
      return;
    }
    this.setState({ loading: true, data: [] });
    const { accountId, privateKey, publicKey } = client;
    const { mailing_id: mailingId } = mailing;
    axios
      .get(
        `/api/v1/clients/${accountId}/mailings/${mailingId}/clicks?privateKey=${privateKey}&publicKey=${publicKey}`
      )
      .then(response => {
        if (response.status === 200) {
          const data = response.data;
          return this.setState({
            data,
            error: null,
            loading: false
          });
        }
        this.setState({
          error: response.data,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          error: error.toString(),
          loading: false
        });
      });
  }

  downloadData(e) {
    e.preventDefault();
    const data = this.state.data.map(({ email, fields, link }) => ({
      ...link,
      ...fields,
      email
    }));
    const columns = data.reduce((fields, row) => {
      const keys = Object.keys(row);
      return Array.from(new Set([...fields, ...keys]));
    }, []);
    return stringify(data, { header: true, columns }, (err, output) => {
      if (err) {
        return this.setState({ error: err });
      }
      return fileDownload(output, 'overview.csv');
    });
  }

  renderForm(className) {
    const { data, loading } = this.state;
    const downloadButton =
      !loading && !!data && data.length ? (
        <Button onClick={this.downloadData.bind(this)}>Download CSV</Button>
      ) : (
        ''
      );
    return (
      <form className={className}>
        <table>
          <tbody>
            <tr>
              <th>Mailing:</th>
              <td>
                <MailingSelector
                  client={this.props.client}
                  ref={this.mailingSelector}
                  defaultValue={this.state.defaultMailing}
                  onLoaded={this.mailingSelected.bind(this)}
                  onChange={this.mailingSelected.bind(this)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <SubmitButton onClick={this.submitForm.bind(this)} />
                {downloadButton}
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }

  renderLoading(className) {
    return (
      <div className={className}>
        <Loading />
      </div>
    );
  }

  renderReport() {
    const { data, loading } = this.state;
    if (loading) {
      return;
    }
    if (!data || !data.length) {
      return;
    }
    const recordMetric = (to, metricName, value, obj) => {
      if (typeof value === 'undefined') {
        return to;
      }
      const email = obj.email.toLowerCase();

      to.metrics = to.metrics || {};
      to.metrics[metricName] = to.metrics[metricName] || {};
      const m = (to.metrics[metricName][value] = to.metrics[metricName][
        value
      ] || {
        emails: [],
        name: value,
        total_clicks: 0,
        unique_clicks: 0
      });
      if (m.emails.indexOf(email) === -1) {
        m.emails.push(email);
        m.unique_clicks = m.emails.length;
      }
      m.total_clicks++;

      return m;
    };
    const rpt = data.reduce((report, record) => {
      const link = recordMetric(report, 'link', record.link.link_name, record);
      const zip = recordMetric(report, 'zip', record.fields.zip, record);
      const state = recordMetric(report, 'state', record.fields.state, record);
      recordMetric(link, 'major', record.fields.majorone, record);
      recordMetric(link, 'major', record.fields.majortwo, record);
      recordMetric(zip, 'major', record.fields.majorone, record);
      recordMetric(zip, 'major', record.fields.majortwo, record);
      recordMetric(state, 'major', record.fields.majorone, record);
      recordMetric(state, 'major', record.fields.majortwo, record);
      return report;
    }, {});
    return (
      <div>
        <ClicksByDate
          title="Clicks by Date"
          data={data.map(({ timestamp, link }) =>
            Object.assign({
              date: timestamp.substr(3, 10),
              link_name: link.link_name
            })
          )}
        />
        <LinksMetricsView title="Metrics by Link" data={rpt.metrics.link} />
        <StateMetricsView
          title="Metrics by State"
          state={rpt.metrics.state}
          zip={rpt.metrics.zip}
        />
      </div>
    );
  }

  render() {
    const loading = !!this.state.loading;
    const form = this.renderForm(loading ? 'hidden' : '');
    const loadingIndicator = this.renderLoading(loading ? '' : 'hidden');
    const report = this.renderReport();
    return (
      <div>
        <h1>Mailing Overview Report</h1>
        {loadingIndicator}
        {form}
        {report}
      </div>
    );
  }
}

import React from 'react';
import { Link, NavLink } from 'react-router-dom';

export const Nav = ({ pages, logoutHandler }) => {
  const links = pages
    .filter(({ caption }) => !!caption)
    .map(({ caption, path }) => {
      return (
        <li key={caption}>
          <NavLink to={path} exact={true} activeClassName="active">
            {caption}
          </NavLink>
        </li>
      );
    })
    .concat(
      <li key="logout">
        <Link to="/" onClick={logoutHandler}>
          Logout
        </Link>
      </li>
    );

  return (
    <nav>
      <ul>{links}</ul>
    </nav>
  );
};

import React, { Component } from 'react';
import { default as RSelect } from 'react-select';

export class Select extends Component {
  constructor(props) {
    super();
    this.select = React.createRef();
    this.state = {
      items: props.items || [],
      selected: undefined
    };
  }

  selectionChanged(selected) {
    this.setState({ selected });
  }

  getValue() {
    if (!this.state.selected) {
      return (this.getDefaultValue() || {}).value;
    }
    return this.state.selected.value;
  }

  getDefaultValue() {
    const { defaultValue } = this.props;
    if (!defaultValue) {
      return false;
    }
    const { items = [] } = this.state;
    const item = items
      .filter(item => item === defaultValue || item.value === defaultValue)
      .shift();
    return item;
  }

  renderSelect() {
    const { items = [] } = this.state;

    const options = items.map(item => {
      if (typeof item === 'string') {
        return { value: item, label: item };
      }
      return item;
    });
    const defaultValue = this.getDefaultValue() || options[0];

    return (
      <RSelect
        defaultValue={defaultValue}
        ref={this.select}
        onChange={this.selectionChanged.bind(this)}
        options={options}
      />
    );
  }

  render() {
    return this.renderSelect();
  }
}

export default Select;

import React, { Component } from 'react';
import DataTable from '../../components/datatable';
import LinkButton from '../../components/linkbutton';
import DeleteButton from '../../components/deletebutton';
import ErrorPanel from '../../components/errorpanel';
import { withRouter } from 'react-router';
import axios from 'axios';
const CancelToken = axios.CancelToken;

export class UsersList extends Component {
  static caption = 'Users';
  static path = '/users';
  static isAdmin = true;

  constructor() {
    super();
    this.state = { inPopover: false };
  }

  confirmDelete(user) {
    this.setState({ inPopover: true, user });
  }

  deleteUser(user) {
    this.requestSource = CancelToken.source();
    axios
      .delete(`/auth/user/${user._id}`, {
        cancelToken: this.requestSource.token
      })
      .then(() => this.props.history.push('/'))
      .catch(error => {
        if (axios.isCancel(error)) {
          return;
        }
        this.setState({ error });
      });
  }

  componentWillUnmount() {
    if (this.requestSource) {
      this.requestSource.cancel();
    }
  }

  render() {
    return (
      <div>
        <h1>Users</h1>
        <ErrorPanel error={this.state.error} />
        <DataTable
          url="/auth/users"
          columns={['email', 'groups']}
          actions={user => (
            <div>
              <LinkButton href={`/user/${user._id}`}>Edit</LinkButton>
              <DeleteButton onDelete={() => this.deleteUser(user)}>
                Delete
              </DeleteButton>
            </div>
          )}
        />
        <div
          style={{
            padding: '5px 4px',
            borderBottom: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black'
          }}
        >
          <LinkButton href={`/user/new`}>New</LinkButton>
        </div>
      </div>
    );
  }
}

export const Users = withRouter(UsersList);

import React, { Component } from 'react';

export class FileUploadInput extends Component {
  constructor() {
    super();
    this.edit = React.createRef();
  }
  getValue() {
    return this.edit.current.value;
  }
  render() {
    const {
      name,
      accept = '.xlsx,.xlsb,.xlsm,.xls,.xml,.csv,.txt,.ods,.fods,.uos,.sylk,.dif,.dbf,.prn,.qpw,.123,.wb*,.wq*,.html,.htm',
      multiple = true
    } = this.props;
    return (
      <input
        ref={this.edit}
        name={name}
        id={name}
        type="file"
        accept={accept}
        multiple={multiple}
      />
    );
  }
}

export default FileUploadInput;

import React, { Component } from 'react';
import Loading from '../../components/loading';
import TextInput from '../../components/textinput';
import SubmitButton from '../../components/submitbutton';
import DeleteButton from '../../components/deletebutton';
import Button from '../../components/button';
import Select from 'react-select';
import cronparser from 'cron-parser';
import axios from 'axios';
const CancelToken = axios.CancelToken;

const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

//console.log(cronparser.parseExpression('*/2 * * * *')._fields);

//*
class Checkbox extends Component {
  constructor({ checked = false }) {
    super();
    this.state = { checked };
  }

  toggleChecked(event) {
    const { checked } = event.target;
    this.setState({ checked });
    this.props.onChange && this.props.onChange(checked);
  }

  getValue() {
    return this.state.checked;
  }

  render() {
    const { children } = this.props;
    const { checked } = this.state;
    return (
      <label>
        <input
          type="checkbox"
          onChange={this.toggleChecked.bind(this)}
          checked={checked}
        />
        {children}
      </label>
    );
  }
}

class Tab extends Component {
  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label }
    } = this;

    let className = 'tab-list-item';

    if (activeTab === label) {
      className += ' tab-list-active';
    }

    return (
      <li className={className} onClick={onClick}>
        {label}
      </li>
    );
  }
}

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label
    };
  }

  onClickTabItem = tab => {
    this.setState({ activeTab: tab });
  };

  activeTab() {
    return this.state.activeTab;
  }

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab }
    } = this;

    return (
      <div className="tabs">
        <ol className="tab-list">
          {children.map(child => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {children.map(child => {
            if (child.props.label !== activeTab) {
              return undefined;
            }
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

class CronEditor extends Component {
  constructor({ defaultValue }) {
    super();
    this.state = { value: defaultValue, tabIndex: 0 };
    this.advanced = React.createRef();
    this.hours = React.createRef();
    this.monday = React.createRef();
    this.tuesday = React.createRef();
    this.wednesday = React.createRef();
    this.thursday = React.createRef();
    this.friday = React.createRef();
    this.saturday = React.createRef();
    this.sunday = React.createRef();
  }

  getValue() {
    return this.state.value;
  }

  valueChanged(value) {
    this.setState({ value, error: '' });
    this.props.onChange && this.props.onChange(value);
  }

  editorChanged(e) {
    const { value } = e.target;
    try {
      cronparser.parseExpression(value);
      this.valueChanged(value);
    } catch (error) {
      this.setState({ error });
    }
  }

  updateValue(updates = {}) {
    const fields = cronparser.parseExpression(this.state.value)._fields;
    const hours = isNumeric(updates.hours)
      ? updates.hours
      : (fields.hour || []).shift() || 0;
    const days = [
      'null',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ]
      .reduce((days, day, index) => {
        if (typeof updates[day] === 'boolean') {
          if (updates[day]) {
            return [...days, index];
          }
          return days;
        }
        if (this[day] && this[day].current && this[day].current.getValue()) {
          return [...days, index];
        }
        return days;
      }, [])
      .join(',');
    const newValue = `0 ${hours} * * ${days}`;
    if (this.state.value !== newValue) {
      this.setState({ value: newValue });
      return this.props.onChange && this.props.onChange(newValue);
    }
  }

  dayChanged(dayNumber) {
    const days = [
      'null',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ];
    return checked => {
      this.updateValue({ [days[dayNumber]]: checked });
    };
  }

  hoursChanged({ value }) {
    this.updateValue({ hours: +(value / 100) });
  }

  render() {
    const { value } = this.state;
    const hourOptions = [...new Array(24).keys()]
      .map(v => (v.toString() + '00').padStart(4, '0'))
      .map(label => ({
        label,
        value: label
      }));
    const fields = cronparser.parseExpression(value)._fields;
    const hoursDefaultValue =
      hourOptions
        .filter(({ value }) => fields.hour.indexOf(+(value / 100)) > -1)
        .shift() || hourOptions[0];
    const monday = fields.dayOfWeek.indexOf(1) > -1;
    const tuesday = fields.dayOfWeek.indexOf(2) > -1;
    const wednesday = fields.dayOfWeek.indexOf(3) > -1;
    const thursday = fields.dayOfWeek.indexOf(4) > -1;
    const friday = fields.dayOfWeek.indexOf(5) > -1;
    const saturday = fields.dayOfWeek.indexOf(6) > -1;
    const sunday =
      fields.dayOfWeek.indexOf(7) > -1 || fields.dayOfWeek.indexOf(0) > -1;
    const advancedErrorClass = this.state.error ? 'error' : '';

    return (
      <div>
        <Tabs>
          <div label="Weekly">
            <div>
              Run At (zulu time):{' '}
              <Select
                options={hourOptions}
                defaultValue={hoursDefaultValue}
                onChange={this.hoursChanged.bind(this)}
              />
            </div>
            <div>
              Days of week:
              <ol className="checklist">
                <li>
                  <Checkbox
                    ref={this.monday}
                    onChange={this.dayChanged(1)}
                    checked={monday}
                  >
                    Monday
                  </Checkbox>
                </li>
                <li>
                  <Checkbox
                    ref={this.tuesday}
                    onChange={this.dayChanged(2)}
                    checked={tuesday}
                  >
                    Tuesday
                  </Checkbox>
                </li>
                <li>
                  <Checkbox
                    ref={this.wednesday}
                    onChange={this.dayChanged(3)}
                    checked={wednesday}
                  >
                    Wednesday
                  </Checkbox>
                </li>
                <li>
                  <Checkbox
                    ref={this.thursday}
                    onChange={this.dayChanged(4)}
                    checked={thursday}
                  >
                    Thursday
                  </Checkbox>
                </li>
                <li>
                  <Checkbox
                    ref={this.friday}
                    onChange={this.dayChanged(5)}
                    checked={friday}
                  >
                    Friday
                  </Checkbox>
                </li>
                <li>
                  <Checkbox
                    ref={this.saturday}
                    onChange={this.dayChanged(6)}
                    checked={saturday}
                  >
                    Saturday
                  </Checkbox>
                </li>
                <li>
                  <Checkbox
                    ref={this.sunday}
                    onChange={this.dayChanged(7)}
                    checked={sunday}
                  >
                    Sunday
                  </Checkbox>
                </li>
              </ol>
            </div>
          </div>
          <div label="Advanced">
            <div className={advancedErrorClass}>
              <TextInput
                ref={this.advanced}
                defaultValue={value}
                onChange={this.editorChanged.bind(this)}
              />
            </div>
          </div>
        </Tabs>
      </div>
    );
  }
}
//*/

class Subscription extends Component {
  constructor({ subscription }) {
    super();
    this.state = { subscription: subscription };
  }

  changeField(fieldName, value) {
    const subscription = { ...this.state.subscription, [fieldName]: value };
    this.setState({
      subscription
    });
    this.props.onChange && this.props.onChange(subscription);
  }

  deleteRowClick() {
    this.props.onDelete && this.props.onDelete();
  }

  intervalChanged(interval) {
    this.changeField('interval', interval);
  }

  fieldChanged(fieldName, validation) {
    return e => {
      const { value } = e.target;
      const { fieldsOk = {} } = this.state;
      if (validation) {
        const okay = (fieldsOk[fieldName] = !!validation.exec(value));
        if (!okay) {
          return this.setState({ fieldsOk });
        }
        this.setState({ fieldsOk });
      }
      this.changeField(fieldName, value);
    };
  }

  reportChanged({ value }) {
    this.changeField('report', value);
  }

  getValue() {
    return this.state;
  }

  componentDidUpdate(prevProps) {
    const prevSubscription = prevProps.subscription;
    const subscription = this.props.subscription;
    const keys = Array.from(
      new Set([...Object.keys(prevSubscription), Object.keys(subscription)])
    );
    const dirty = !keys.every(
      key => prevSubscription[key] === subscription[key]
    );
    if (dirty) {
      this.setState(this.props);
    }
  }

  isValid() {
    const { fieldsOk = {} } = this.state;
    return Object.values(fieldsOk).every(v => !!v);
  }

  render() {
    const { subscription } = this.state;
    const {
      to,
      days = 14,
      filter,
      interval = '* * * * *',
      report = 'clicksDetail'
    } = subscription;
    const reportOptions = [
      { value: 'clicksSummary', label: 'Summary' },
      { value: 'clicksDetail', label: 'Detail' }
    ];
    const reportDefaultValue =
      reportOptions.filter(ro => ro.value === report).shift() ||
      reportOptions[0];
    const className = this.isValid() ? '' : 'error';
    return (
      <tr className={className}>
        <td>
          <TextInput
            onChange={this.fieldChanged('to', /^.+@.+\..+$/)}
            defaultValue={to}
          />
        </td>
        <td>
          <TextInput
            onChange={this.fieldChanged('days', /^[0-9]+$/)}
            defaultValue={days}
          />
        </td>
        <td>
          <TextInput
            onChange={this.fieldChanged('filter')}
            defaultValue={filter}
          />
        </td>
        <td>
          <CronEditor
            defaultValue={interval}
            onChange={this.intervalChanged.bind(this)}
          />
        </td>
        <td>
          <Select
            options={reportOptions}
            defaultValue={reportDefaultValue}
            onChange={this.reportChanged.bind(this)}
          />
        </td>
        <td>
          <DeleteButton onDelete={this.deleteRowClick.bind(this)}>
            Delete
          </DeleteButton>
        </td>
      </tr>
    );
  }
}

class SubscriptionsView extends Component {
  constructor({ subscriptions = [] }) {
    super();
    this.state = {
      subscriptions: subscriptions.map((s, _id) => ({ _id, ...s })),
      key: subscriptions.length
    };
  }
  getValue() {
    return this.state.subscriptions.map(
      ({ _id, ...subscription }) => subscription
    );
  }
  subscriptionChanged(index) {
    return subscription => {
      const { subscriptions } = this.state;
      subscriptions.splice(index, 1, subscription);
      this.setState(subscriptions);
    };
  }
  addSubscription() {
    const { subscriptions, key } = this.state;
    subscriptions.push({
      _id: key,
      to: '',
      days: 14,
      filter: '',
      interval: '0 0 * * *',
      report: 'clicksDetail'
    });
    this.setState({ subscriptions, key: key + 1 });
  }
  deleteSubscription(index) {
    return () => {
      const { subscriptions } = this.state;
      subscriptions.splice(index, 1);
      this.setState(subscriptions);
    };
  }
  render() {
    const { subscriptions = [] } = this.state;
    this.edits = subscriptions.map((subscription, index) => (
      <Subscription
        onChange={this.subscriptionChanged(index)}
        onDelete={this.deleteSubscription(index)}
        subscription={subscription}
        key={subscription._id}
      />
    ));
    return (
      <table>
        <thead>
          <tr>
            <th>To</th>
            <th>Days to Report</th>
            <th>Filter</th>
            <th>Interval</th>
            <th>Report</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{this.edits}</tbody>
      </table>
    );
  }
}

export class Subscriptions extends Component {
  static caption = 'Subscriptions';
  static path = '/report/subscriptions';
  static isAdmin = false;

  constructor() {
    super();
    this.subscriptionsView = React.createRef();
    this.state = {};
  }

  renderLoading() {
    return <Loading />;
  }

  componentWillUnmount() {
    if (this.requestSource) {
      this.requestSource.cancel();
    }
  }

  componentDidMount() {
    return this.loadSubscriptions();
  }

  submitClicked() {
    const subscriptions = this.subscriptionsView.current.getValue();
    const { _id: clientId } = this.props.client;
    const url = `/api/v1/clients/${clientId}/subscriptions`;

    this.requestSource = CancelToken.source();
    axios
      .put(url, subscriptions, { cancelToken: this.requestSource.token })
      .then(response => {
        const data = response.data;
        if (response.status === 200) {
          return this.loadSubscriptions();
        }
        return this.setState({
          error: data
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return;
        }
        this.setState({ error });
      });
  }

  loadSubscriptions() {
    const { client } = this.props;
    if (!client) {
      return;
    }
    const { _id: clientId } = client;
    const url = `/api/v1/clients/${clientId}`;

    this.setState({ loading: true });
    this.requestSource = CancelToken.source();
    axios
      .get(url, { cancelToken: this.requestSource.token })
      .then(response => {
        const data = response.data;
        if (response.status === 200) {
          const client = data;
          const { subscriptions = [] } = client;
          return this.setState({ error: '', subscriptions, loading: false });
        }
        return this.setState({
          error: data,
          loading: false
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return;
        }
        this.setState({ error, loading: false });
      });
  }

  addSubscription() {
    this.subscriptionsView.current.addSubscription();
  }

  render() {
    const { subscriptions, loading } = this.state;
    if (!subscriptions || loading) {
      return this.renderLoading();
    }
    return (
      <div>
        <h1>Manage Subscriptions</h1>
        <SubscriptionsView
          subscriptions={subscriptions}
          ref={this.subscriptionsView}
        />
        <SubmitButton onClick={this.submitClicked.bind(this)} />
        <Button onClick={this.addSubscription.bind(this)}>Add</Button>
      </div>
    );
  }
}

import React, { Component } from 'react';
import DataTable from '../../components/datatable';
import Button from '../../components/button';
import DeleteButton from '../../components/deletebutton';
import ErrorPanel from '../../components/errorpanel';
import axios from 'axios';
import queryString from 'query-string';
const CancelToken = axios.CancelToken;
const fileDownload = require('js-file-download');

export class Listing extends Component {
  static caption = 'Files';
  static path = '/files';

  constructor() {
    super();
    this.state = {};
    this.datatable = React.createRef();
  }

  componentWillUnmount() {
    if (this.requestSource) {
      this.requestSource.cancel();
    }
    if (this.downloadTimer) {
      clearTimeout(this.downloadTimer);
    }
  }

  filterData(data = []) {
    const { user } = this.props;
    if (user.groups.indexOf('systems/admin') > -1) {
      return data;
    }
    return data.filter((row) => !/^cache\//.exec(row.fileName));
  }

  reformData(data) {
    const { client } = this.props;
    const { accountId } = client || {};
    const reStripAccountPrefix = new RegExp(`^${accountId}/`, 'i');
    return this.filterData(
      data.items
        .sort(
          (a, b) =>
            new Date(b.LastModified).getTime() -
            new Date(a.LastModified).getTime()
        )
        .map((fn) => {
          if (typeof fn === 'string') {
            return { fileName: fn.replace(reStripAccountPrefix, '') };
          }
          if (typeof fn === 'object') {
            const { Key, LastModified } = fn;
            return {
              fileName: Key.replace(reStripAccountPrefix, ''),
              lastModified: new Date(LastModified).toLocaleString(),
            };
          }
          return fn;
        })
    );
  }

  downloadFile({ fileName }) {
    const { client } = this.props;
    const { accountId } = client || {};
    const url = `/api/v1/clients/${accountId}/files/${fileName}`;

    this.requestSource = CancelToken.source();

    axios
      .get(url, { responseType: 'blob', cancelToken: this.requestSource.token })
      .then((response) => {
        const { data } = response;
        if (response.status === 200) {
          this.setState({ error: undefined });
          return fileDownload(data, fileName.split('/').pop());
        }
        return this.setState({
          error: data,
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        this.setState({ error, loading: false });
      });
  }

  componentDidMount() {
    const { download } = queryString.parse(this.props.location.search);
    if (download) {
      return (this.downloadTimer = setTimeout(
        () => this.downloadFile({ fileName: download }),
        5000
      ));
    }
  }

  deleteFile({ fileName }) {
    const { client } = this.props;
    const { accountId } = client || {};
    const url = `/api/v1/clients/${accountId}/files/${fileName}`;

    this.requestSource = CancelToken.source();

    axios
      .delete(url, { cancelToken: this.requestSource.token })
      .then((response) => {
        const { data } = response.data;
        if (response.status === 200) {
          this.setState({ error: undefined });
          return this.refreshView();
        }
        return this.setState({
          error: data,
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        this.setState({ error, loading: false });
      });
  }

  refreshView() {
    this.datatable.current.refresh();
  }

  render() {
    const { client, user } = this.props;
    const isAdmin = user.groups.indexOf('systems/admin') > -1;
    const { accountId } = client || {};
    if (!accountId) {
      return <div />;
    }
    return (
      <div>
        <h1>Files</h1>
        <ErrorPanel error={this.state.error} />
        <DataTable
          ref={this.datatable}
          url={`/api/v1/clients/${accountId}/meta/files/`}
          reform={this.reformData.bind(this)}
          actions={(file) => {
            if (!isAdmin) {
              return (
                <Button onClick={() => this.downloadFile(file)}>
                  Download
                </Button>
              );
            }
            return (
              <div>
                <Button onClick={() => this.downloadFile(file)}>
                  Download
                </Button>
                <DeleteButton onDelete={() => this.deleteFile(file)}>
                  Delete
                </DeleteButton>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

import React from 'react';
import { Nav } from './nav';
import './header.css';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import ClientSelector from './clientselector';

const HeaderC = (props) => {
  const { client } = queryString.parse(props.location.search);
  return (
    <header>
      <ClientSelector
        className="float-right"
        onChange={props.onClientSelected}
        onLoaded={props.onClientSelected}
        defaultValue={client}
        {...props}
      />
      <h1>Stewart 360 - Administrative Console</h1>
      <Nav {...props} />
    </header>
  );
};

export const Header = withRouter(HeaderC);

import React, { Component } from 'react';
import axios from 'axios';
import './footer.css';

export class Footer extends Component {
  constructor() {
    super();
    this.state = { loading: true };
  }

  componentDidMount() {
    axios
      .get('/health')
      .then(response => {
        const data = response.data;
        if (response.status === 200) {
          return this.setState({
            loading: false,
            ...data
          });
        }
        return this.setState({
          error: data,
          loading: false
        });
      })
      .catch(error => this.setState({ error, loading: false }));
  }

  renderPlaceholder() {
    return <div />;
  }

  renderFooter() {
    const { version } = this.state;
    return (
      <footer>
        <table>
          <tbody>
            <tr>
              <td className="left" width="33%">
                {' '}
                &nbsp;{' '}
              </td>
              <td className="center" width="33%">
                Content &copy; Stewart 360
              </td>
              <td className="right" width="33%">
                Version: {version}
              </td>
            </tr>
          </tbody>
        </table>
      </footer>
    );
  }

  render() {
    if (this.state.loading) {
      return this.renderPlaceholder();
    }
    if (this.state.error) {
      return this.renderPlaceholder();
    }
    return this.renderFooter();
  }
}

export default Footer;

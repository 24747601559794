import React, { Component } from 'react';
import ErrorPanel from './errorpanel';
import Select from 'react-select';
import axios from 'axios';
const CancelToken = axios.CancelToken;

const getErrorMessage = (error)=>{
  if(error.response){
    if(error.response.data){/*
      if(error.response.data.errmsg){
        return error.response.data.errmsg;
      }
      //*/
      return error.response.data;
    }
  }
  return error.errmsg || error.toString();
}

const sortMailings = (a, b) => {
  if (a.send_finished && b.send_finished) {
    const aDate = new Date(a.send_finished.substr(3)).getTime();
    const bDate = new Date(b.send_finished.substr(3)).getTime();
    return bDate - aDate;
  }
  if (a.send_finished) {
    return -1;
  }
  if (b.send_finished) {
    return 1;
  }
  return 0;
};

export class MailingsSelector extends Component {
  constructor(props) {
    super();
    this.select = React.createRef();
    this.state = {
      mailings: [],
      selected: [],
      loading: true,
      mailing: props.defaultValue
    };
  }

  componentWillUnmount() {
    if(this.requestSource){
      this.requestSource.cancel();
    }
  }

  getMailings() {
    const client = this.props.client;
    if (!client) {
      return;
    }
    const { accountId, privateKey, publicKey } = client;
    this.setState({ loading: true, mailings: [] });
    return setImmediate(() => {
      this.requestSource = CancelToken.source();
      axios
        .get(
          `/api/v1/clients/${accountId}/mailings?privateKey=${privateKey}&publicKey=${publicKey}`,
          { cancelToken: this.requestSource.token }
        )
        .then(response => {
          if (response.status === 200) {
            const mailings = response.data.filter(({mailing_type})=>['s','m'].indexOf(mailing_type)>-1);
            return this.setState({
              mailings: mailings.sort(sortMailings),
              error: null,
              loading: false
            });
          }
          this.setState({
            error: response.data,
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            error: getErrorMessage(error),
            loading: false
          });
        });
    });
  }

  componentDidMount() {
    this.getMailings();
  }

  selectionChanged(selected){
    this.setState({selected});
  }

  getValue() {
    const selected = (this.state.selected||[]).map(item=>item.value);
    const mailings = this.state.mailings
      .filter(c => selected.indexOf(c.mailing_id)>-1);
    return mailings;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loading && !this.state.loading) {
      if (typeof this.props.onLoaded === 'function') {
        return this.props.onLoaded(this);
      }
    }
    if (prevProps.client !== this.props.client) {
      this.getMailings();
    }
  }

  renderSelect() {
    const { mailings = [] } = this.state;
    const loaded = mailings && mailings.length;
    const isLoading = !loaded;

    const options = mailings.map(({ mailing_id, name }, i) => {
      return {value: mailing_id, label: name};
    });

    return (
      <Select isMulti isLoading={isLoading} ref={this.select} onChange={this.selectionChanged.bind(this)} options={options} />
    );
  }

  renderError() {
    return <ErrorPanel error={this.state.error} />;
  }

  render() {
    if (this.state.error) {
      return this.renderError();
    }
    return this.renderSelect();
  }
}

export default MailingsSelector;

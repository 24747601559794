import React, { Component } from 'react';
import Loading from '../../components/loading';
import ErrorPanel from '../../components/errorpanel';
import SubmitButton from '../../components/submitbutton';
import { TextInput } from '../../components/textinput';
import { PasswordInput } from '../../components/passwordinput';
import Select from 'react-select';
import { withRouter } from 'react-router';
import axios from 'axios';
const CancelToken = axios.CancelToken;

class GroupsSelector extends Component {
  constructor(props) {
    super();
    this.groupselect = React.createRef();
    this.state = { groups: props.groups || [] };
  }

  componentWillUnmount() {
    if (this.requestSource) {
      this.requestSource.cancel();
    }
  }

  getValue() {
    const { groups } = this.state;
    if (!groups) {
      return [];
    }
    return groups.map(g => (typeof g === 'string' ? g : g.value));
  }

  componentDidMount() {
    this.requestSource = CancelToken.source();
    axios
      .get('/api/v1/clients', { cancelToken: this.requestSource.token })
      .then(response => {
        if (response.status === 200) {
          const clients = response.data;
          return this.setState({
            clients,
            error: null,
            loading: false
          });
        }
      })
      .catch(error => {
        this.setState({
          error: error.toString(),
          loading: false
        });
      });
  }

  onChange(groups) {
    this.setState({ groups });
  }

  renderLoading() {
    return '';
  }

  renderError() {
    return <ErrorPanel error={this.state.error} />;
  }

  renderSelect() {
    const { clients } = this.state;
    const options = [{ value: 'systems/admin', label: 'Admin' }].concat(
      clients.map(({ short, name }) => ({
        value: `clients/${short.toUpperCase()}`,
        label: name
      }))
    );
    const { groups = [] } = this.props;
    const defaultValue = groups
      .map(group => {
        const val = options
          .filter(
            option =>
              !option.value.localeCompare(group, undefined, {
                sensitivity: 'accent'
              })
          )
          .shift();
        return val;
      })
      .filter(v => !!v);
    return (
      <Select
        isMulti
        defaultValue={defaultValue}
        ref={this.groupSelect}
        onChange={this.onChange.bind(this)}
        options={options}
      />
    );
  }

  render() {
    const { clients, error } = this.state;
    if (error) {
      return this.renderError();
    }
    if (!Array.isArray(clients)) {
      return this.renderLoading();
    }
    return this.renderSelect();
  }
}

class UserMissingFieldError extends Error {
  constructor(fieldName) {
    super();
    this.message = `Required field ${fieldName} can not be blank!`;
  }
}

class UserEditor extends Component {
  static path = '/user/:id';

  constructor() {
    super();
    this.state = { state: 'Loading' };
    this.username = React.createRef();
    this.email = React.createRef();
    this.password = React.createRef();
    this.confirmPassword = React.createRef();
    this.groups = React.createRef();
  }

  componentWillUnmount() {
    if (this.requestSource) {
      this.requestSource.cancel();
    }
  }

  componentDidMount() {
    const { id = 'new' } = this.props.match.params;
    if (id.toLowerCase() === 'new') {
      return setImmediate(() => {
        this.setState({ state: 'Ready' });
      });
    }

    const url = `/auth/user/${id}`;

    this.requestSource = CancelToken.source();

    axios
      .get(url, { cancelToken: this.requestSource.token })
      .then(response => {
        this.requestSource = null;
        const user = response.data;
        if (response.status === 200) {
          return this.setState({
            user,
            error: null,
            state: 'Ready'
          });
        }
        return this.setState({
          error: user,
          state: 'Error'
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return;
        }
        this.setState({ error, state: 'Error' });
      });
  }

  getValue(refName) {
    if (this[refName] && this[refName].current) {
      return this[refName].current.getValue();
    }
    const { user = {} } = this.state;
    return user[refName];
  }

  isUpdate() {
    const { user = {} } = this.state;
    return !!user._id;
  }

  submitForm() {
    const isUpdate = this.isUpdate();
    const reUsernameValid = /.+@.+\..+/i;
    const { user = {} } = this.state;

    const password = this.getValue('password');
    const confirmPassword = this.getValue('confirmPassword');

    if (password || confirmPassword) {
      if (password !== confirmPassword) {
        const passwordError = new Error(
          'Password and confirm password must match.'
        );
        return this.setState({ error: passwordError });
      }
      user.password = password;
    }

    user.email = this.getValue('email');
    user.username = this.getValue('username');
    user.groups = this.getValue('groups');

    if (!isUpdate) {
      if (!user.username) {
        return this.setState({ error: new UserMissingFieldError('username') });
      }
      if (!reUsernameValid.exec(user.username)) {
        return this.setState({
          error: new Error('Username must be a valid email address')
        });
      }
      if (!user.password) {
        return this.setState({ error: new UserMissingFieldError('password') });
      }
    }

    if (!Array.isArray(user.groups) || user.groups.length === 0) {
      const groupsError = new Error('User must be in at least one group.');
      return this.setState({ error: groupsError });
    }

    this.setState({ error: '' });
    const method = isUpdate ? 'put' : 'post';
    const url = isUpdate ? `/auth/user/${user._id}` : '/auth/user';

    this.requestSource = CancelToken.source();
    axios[method](url, user, { cancelToken: this.requestSource.token })
      .then(response => {
        const data = response.data;
        if (response.status === 200) {
          return this.props.history.push('/users');
        }
        return this.setState({
          error: data
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return;
        }
        this.setState({ error });
      });
  }

  renderError() {
    return (
      <div>
        <h1>User</h1>
        <ErrorPanel error={this.state.error} />
      </div>
    );
  }

  renderLoading() {
    return <Loading />;
  }

  groupsEditor(user) {
    return (
      <tr>
        <th>Groups</th>
        <td>
          <GroupsSelector ref={this.groups} groups={user.groups} />
        </td>
      </tr>
    );
  }

  renderBasicUser(user) {
    const username = this.isUpdate() ? (
      user.username
    ) : (
      <TextInput ref={this.username} />
    );
    return (
      <div>
        <form>
          <ErrorPanel error={this.state.error} />
          <table>
            <tbody>
              <tr>
                <th>Username:</th>
                <td>{username}</td>
              </tr>
              <tr>
                <th>Password:</th>
                <td>
                  <PasswordInput ref={this.password} />
                </td>
              </tr>
              <tr>
                <th>Confirm Password:</th>
                <td>
                  <PasswordInput ref={this.confirmPassword} />
                </td>
              </tr>
              {this.groupsEditor(user)}
              <tr>
                <td colSpan={2}>
                  <SubmitButton onClick={this.submitForm.bind(this)} />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    );
  }

  renderOAuthUser(user) {
    return (
      <div>
        <form>
          <ErrorPanel error={this.state.error} />
          <table>
            <tbody>
              <tr>
                <th>Email:</th>
                <td>{user.email}</td>
              </tr>
              {this.groupsEditor(user)}
              <tr>
                <td colSpan={2}>
                  <SubmitButton onClick={this.submitForm.bind(this)} />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    );
  }

  renderUser(user) {
    if (user.isOAuth) {
      return this.renderOAuthUser(user);
    }
    return this.renderBasicUser(user);
  }

  renderReady() {
    const { user = {} } = this.state;
    return (
      <div>
        <h1>User</h1>
        {this.renderUser(user)}
      </div>
    );
  }

  render() {
    return this[`render${this.state.state}`]();
  }
}

export const User = withRouter(UserEditor);

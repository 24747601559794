import React, { Component } from 'react';
import axios from 'axios';
import MailingsSelector from '../../components/mailingsselector';
import FilenameInput from '../../components/filenameinput';
import FileUploadInput from '../../components/fileupload';
import SubmitButton from '../../components/submitbutton';
import ErrorPanel from '../../components/errorpanel';
import Loading from '../../components/loading';
const fileDownload = require('js-file-download');
const stringify = require('csv-stringify');
const CancelToken = axios.CancelToken;

const getData = (data, callback) => {
  if (data instanceof Blob) {
    const fr = new FileReader();
    fr.onload = function() {
      return callback(null, JSON.parse(this.result));
    };
    return fr.readAsText(data);
  }
  return callback(null, data);
};

export class Matches extends Component {
  static caption = 'Match Report';
  static path = '/reports/match';
  static isAdmin = true;

  constructor() {
    super();
    this.reportFilter = React.createRef();
    this.selectedMailings = React.createRef();
    this.outputFilename = React.createRef();
    this.clientList = React.createRef();
    this.state = {
      state: 'Input',
      clients: [],
      error: null
    };
  }

  submitForm() {
    const selectedMailings = this.selectedMailings.current;
    const outputFilenameEdit = this.outputFilename.current;
    const client = this.props.client || {};

    const mailings = selectedMailings.getValue();

    if (mailings.length === 0) {
      return this.setState({ error: 'Must select at least one mailing.' });
    }

    const filter = '^' + mailings.map(m => m.name).join('|') + '$';

    const { privateKey, publicKey, accountId } = client;
    const outputFilename = outputFilenameEdit.getValue();
    if (!filter) {
      return this.setState({ error: 'Filter is required' });
    }

    const formData = new FormData();
    formData.append('privateKey', privateKey);
    formData.append('publicKey', publicKey);
    formData.append('accountId', accountId);
    formData.append('filter', filter);
    Array.from(document.querySelectorAll('[type=file]')).forEach(input => {
      const name = input.name;
      const files = input.files;
      for (let i = 0; i < files.length; i++) {
        formData.append(`${name}`, files[i]);
      }
    });

    this.requestSource = CancelToken.source();
    axios
      .post('/api/v1/lists/match', formData, {
        responseType: 'blob',
        cancelToken: this.requestSource.token
      })
      .then(response => {
        this.setState({
          error: '',
          state: 'Input'
        });
        getData(response.data, (err, rows) => {
          if (err) {
            return this.setState({ error: err });
          }
          const records = rows.reduce((a, b) => a.concat(b), []);

          const columns = records.reduce((fields, row) => {
            const keys = Object.keys(row);
            return Array.from(new Set([...fields, ...keys]));
          }, []);

          return stringify(
            records,
            { header: true, columns },
            (err, output) => {
              if (err) {
                return this.setState({ error: err });
              }
              return fileDownload(output, outputFilename);
            }
          );
        });
      })
      .catch(error => {
        this.setState({
          state: 'Error',
          error
        });
      });
    return this.setState({ error: '', state: 'Requesting' });
  }

  showForm() {
    return (
      <form>
        <ErrorPanel error={this.state.error} />
        <table>
          <tbody>
            <tr>
              <th>Client List:</th>
              <td>
                <FileUploadInput
                  name="list"
                  multiple={false}
                  ref={this.clientList}
                />
              </td>
            </tr>
            <tr>
              <th>Mailings:</th>
              <td>
                <MailingsSelector
                  client={this.props.client}
                  ref={this.selectedMailings}
                />
              </td>
            </tr>
            <tr>
              <th>Ouput Filename:</th>
              <td>
                <FilenameInput
                  ref={this.outputFilename}
                  default="matches.csv"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <SubmitButton onClick={this.submitForm.bind(this)} />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }

  showRequesting() {
    return <Loading />;
  }

  pageContent() {
    switch (this.state.state) {
      case 'Requesting':
        return this.showRequesting();
      default:
        return this.showForm();
    }
  }

  render() {
    const page = this.pageContent();
    return (
      <div>
        <h1>Mailings Report</h1>
        {page}
      </div>
    );
  }
}

import React from 'react';
import googleButton from '../components/login/google_signin_buttons/web/1x/btn_google_signin_dark_normal_web.png';

const GoogleButton = () => (
  <a href="/auth/google">
    <img src={googleButton} alt="sign into Google Button" />
  </a>
);

export class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { loginHandler } = this.props;

    loginHandler && loginHandler(this.state.username, this.state.password);
  }

  render() {
    return (
      <div className="LoginForm">
        <h1>Login form</h1>
        <form>
          <label htmlFor="username">Username: </label>
          <input
            type="text"
            name="username"
            value={this.state.username}
            onChange={this.handleChange}
          />
          <label htmlFor="password">Password: </label>
          <input
            type="password"
            name="password"
            value={this.state.password}
            onChange={this.handleChange}
          />
          <button onClick={this.handleSubmit}>Login</button>
        </form>
        <GoogleButton />
      </div>
    );
  }
}

import React from 'react';
import ErrorPanel from '../components/errorpanel';
import Loading from '../components/loading';
import {Vega} from 'react-vega';
import collect from '../lib/collect';
import axios from 'axios';

const spec = {
  $schema: 'https://vega.github.io/schema/vega/v5.json',
  width: 240,
  height: 240,
  padding: 5,
  data: [
    {
      name: 'table',
      values: [
        {
          name: 'Facebook',
          total: 2,
          unique: 2
        },
        {
          name: 'Twitter',
          total: 4,
          unique: 2
        },
        {
          name: 'visit',
          total: 143,
          unique: 72
        },
        {
          name: 'request info',
          total: 73,
          unique: 72
        },
        {
          name: 'Apply',
          total: 72,
          unique: 72
        },
        {
          name: 'banner',
          total: 74,
          unique: 73
        }
      ]
    }
  ],
  signals: [
    {
      name: 'tooltip2',
      value: {},
      on: [
        {
          events: 'mousemove',
          update: "invert('x', clamp(x(), 0, width))"
        }
      ]
    },
    {
      name: 'tooltip',
      value: {},
      on: [
        {
          events: 'rect:mouseover',
          update: 'datum'
        },
        {
          events: 'rect:mouseout',
          update: '{}'
        }
      ]
    }
  ],
  scales: [
    {
      name: 'xscale',
      type: 'band',
      domain: {
        data: 'table',
        field: 'name'
      },
      range: 'width'
    },
    {
      name: 'yscale',
      type: 'linear',
      domain: {
        data: 'table',
        field: 'total'
      },
      range: 'height',
      zero: true,
      nice: true
    },
    {
      name: 'color',
      type: 'ordinal',
      domain: {
        data: 'table',
        field: 'name'
      },
      range: {
        scheme: 'category20'
      }
    }
  ],
  axes: [
    {
      orient: 'left',
      scale: 'yscale',
      zindex: 1
    },
    {
      orient: 'bottom',
      scale: 'xscale',
      zindex: 1
    }
  ],
  marks: [
    {
      type: 'rect',
      from: {
        data: 'table'
      },
      encode: {
        enter: {
          x: {
            scale: 'xscale',
            field: 'name'
          },
          width: {
            scale: 'xscale',
            band: 1
          },
          y: {
            scale: 'yscale',
            band: 1
          },
          y2: {
            scale: 'yscale',
            value: 0
          },
          fill: {
            value: 'red'
          },
          fillOpacity: {
            value: 0
          },
          zindex: 0
        }
      }
    },
    {
      type: 'rect',
      from: {
        data: 'table'
      },
      encode: {
        enter: {
          x: {
            scale: 'xscale',
            field: 'name'
          },
          width: {
            scale: 'xscale',
            band: 1
          },
          y: {
            scale: 'yscale',
            field: 'total'
          },
          y2: {
            scale: 'yscale',
            value: 0
          },
          fill: {
            scale: 'color',
            field: 'name'
          },
          fillOpacity: {
            value: 0.5
          },
          zindex: 1
        }
      }
    },
    {
      type: 'rect',
      from: {
        data: 'table'
      },
      encode: {
        enter: {
          x: {
            scale: 'xscale',
            field: 'name'
          },
          width: {
            scale: 'xscale',
            band: 1
          },
          y: {
            scale: 'yscale',
            field: 'unique'
          },
          y2: {
            scale: 'yscale',
            value: 0
          },
          fill: {
            scale: 'color',
            field: 'name'
          },
          zindex: 2
        }
      }
    },
    {
      type: 'text',
      encode: {
        enter: {
          align: {
            value: 'center'
          },
          baseline: {
            value: 'bottom'
          },
          fill: {
            value: '#333'
          }
        },
        update: {
          x: {
            scale: 'xscale',
            signal: 'tooltip.name',
            band: 0.5
          },
          y: {
            scale: 'yscale',
            signal: 'tooltip.total',
            offset: -2
          },
          text: {
            signal:
              'tooltip&&tooltip.total?"u"+tooltip.unique+" t"+tooltip.total:""'
          },
          fillOpacity: [
            {
              test: 'datum === tooltip',
              value: 0
            },
            {
              value: 1
            }
          ]
        }
      }
    }
  ]
};

const fetch = (from, callback) => {
  axios
    .get(from)
    .then(response => {
      const data = response.data;
      if ([200, 304].indexOf(response.status) !== -1) {
        return callback(null, data);
      }
      return callback(data);
    })
    .catch(error => callback(error));
};

export class Prototype extends React.Component {
  //static caption = 'Prototype';
  static path = '/prototype';

  constructor() {
    super();
    this.state = { loading: true };
  }

  componentDidMount() {
    return fetch(`/static/data/sample.json`, (error, data) => {
      if (error) {
        return this.setState({ loading: false, error });
      }
      return fetch(`/static/data/zipcodemap.json`, (error, zipcodelookup) => {
        return this.setState({
          zipcodelookup,
          data,
          error,
          loading: false
        });
      });
    });
  }

  loading() {
    return <Loading />;
  }

  render() {
    if (this.state.loading) {
      return this.loading();
    }
    //const style = { height: 400 };
    const data = collect(this.state.data).map(({ link, ...record }) =>
      Object.assign({}, record, { link: link.link_name })
    );
    const linkMetrics = data
      .groupBy('link')
      .instancesBy('email')
      .dictionaryToMap('name')
      .all();
    //console.log(linkMetrics);
    const vegaData = { table: linkMetrics };
    return (
      <div>
        <h1>This is where you try shit</h1>
        <ErrorPanel error={this.state.error}>
          <Vega data={vegaData} spec={spec} />
        </ErrorPanel>
      </div>
    );
  }
}

import React, { Component } from 'react';
import Button from './button';

export class DeleteButton extends Component {
  constructor() {
    super();
    this.state = { confirm: false };
  }

  setConfirm(e) {
    e.preventDefault();
    this.setState({ confirm: true });
  }

  cancelDelete(e) {
    e.preventDefault();
    this.setState({ confirm: false });
  }

  doDelete(e) {
    e.preventDefault();
    this.props.onDelete();
  }

  renderConfirm() {
    return (
      <div>
        Are you sure?
        <Button onClick={this.doDelete.bind(this)}>Delete</Button>
        <Button onClick={this.cancelDelete.bind(this)}>Cancel</Button>
      </div>
    );
  }

  render() {
    if (this.state.confirm) {
      return this.renderConfirm();
    }
    return <Button onClick={this.setConfirm.bind(this)}>Delete</Button>;
  }
}

export default DeleteButton;

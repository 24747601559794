import React, { Component } from 'react';

export class TextInput extends Component {
  constructor() {
    super();
    this.edit = React.createRef();
  }
  getValue() {
    return this.edit.current.value || this.props.default || '';
  }
  render() {
    return (
      <input
        ref={this.edit}
        type="text"
        placeholder={this.props.default || ''}
        defaultValue={this.props.defaultValue}
        onChange={this.props.onChange}
      />
    );
  }
}

export default TextInput;

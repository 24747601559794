import React from 'react';
import './loading.css';

export class Loading extends React.Component {
  render() {
    return (
      <div className="loading black">
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }
}

export default Loading;

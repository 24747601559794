import React, { Component } from 'react';
import Loading from '../../components/loading';
import ErrorPanel from '../../components/errorpanel';
import SubmitButton from '../../components/submitbutton';
import { TextInput } from '../../components/textinput';
import { TextArea } from '../../components/textarea';
//import { PasswordInput } from '../../components/passwordinput';
import { withRouter } from 'react-router';
import axios from 'axios';
const CancelToken = axios.CancelToken;

/*
{
  name: String,
  short: String,
  publicKey: String,
  privateKey: String,
  accountId: Number
}
//*/

class ClientMissingFieldError extends Error {
  constructor(fieldName) {
    super();
    this.message = `Required field ${fieldName} can not be blank!`;
  }
}

class ClientInvalidFieldError extends Error {
  constructor(fieldName, error) {
    super();
    this.message = `Field ${fieldName} ${error}!`;
  }
}

class ClientInvalidJSONError extends Error {
  constructor(fieldName) {
    super();
    this.message = `Value of ${fieldName} is not valid JSON!`;
  }
}

const isValidJSONValue = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    try {
      // eslint-disable-next-line
      const f = new Function('', `return ${value}`);
      return f() || true;
    } catch (e) {
      return false;
    }
  }
};

class ClientEditor extends Component {
  static path = '/client/:id';

  constructor() {
    super();

    this.nameInput = React.createRef();
    this.shortInput = React.createRef();
    //this.publicKeyInput = React.createRef();
    //this.privateKeyInput = React.createRef();
    this.accountIdInput = React.createRef();
    this.columnsInput = React.createRef();
    this.subscriptionsInput = React.createRef();

    this.state = { state: 'Loading' };
  }

  componentWillUnmount() {
    this.requestSource && this.requestSource.cancel();
  }

  componentDidMount() {
    const { id = 'new' } = this.props.match.params;
    if (id.toLowerCase() === 'new') {
      return setImmediate(() => {
        this.setState({ state: 'Ready' });
      });
    }

    const url = `/api/v1/clients/${id}`;

    this.requestSource = CancelToken.source();

    axios
      .get(url, { cancelToken: this.requestSource.token })
      .then((response) => {
        this.requestSource = null;
        const client = response.data;
        if (response.status === 200) {
          return this.setState({
            client,
            error: null,
            state: 'Ready',
          });
        }
        return this.setState({
          error: client,
          state: 'Error',
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        this.setState({ error, state: 'Error' });
      });
  }

  renderError() {
    return (
      <div>
        <h1>Client</h1>
        <ErrorPanel error={this.state.error} />
      </div>
    );
  }

  renderLoading() {
    return <Loading />;
  }

  validateClient(client, callback) {
    setImmediate(() => {
      //const { name, short, publicKey, privateKey, accountId, columns } = client;
      const { name, short, accountId, columns } = client;
      if (!name) {
        return callback(new ClientMissingFieldError('Name'));
      }
      if (!short) {
        return callback(new ClientMissingFieldError('Short'));
      }
      /*
      if (!publicKey) {
        return callback(new ClientMissingFieldError('Public Key'));
      }
      if (!privateKey) {
        return callback(new ClientMissingFieldError('Private Key'));
      }
      */
      if (!accountId) {
        return callback(new ClientMissingFieldError('Account ID'));
      }
      if (columns && !isValidJSONValue(columns)) {
        return callback(new ClientInvalidJSONError('Columns'));
      }
      if (/[^0-9]/.exec(accountId)) {
        return callback(
          new ClientInvalidFieldError(
            'Account ID',
            `"${accountId}" must contain numbers only.`
          )
        );
      }
      /*
      if (/[^0-9a-z]/i.exec(privateKey)) {
        return callback(
          new ClientInvalidFieldError(
            'Private Key',
            `"${privateKey}" must contain numbers and letters only.`
          )
        );
      }
      if (/[^0-9a-z]/i.exec(publicKey)) {
        return callback(
          new ClientInvalidFieldError(
            'Public Key',
            `"${publicKey}" must contain numbers and letters only.`
          )
        );
      }
      */
      return callback(null, client);
    });
  }

  submitClient(client) {
    const { _id: id } = client;
    const method = id ? 'put' : 'post';
    const url = id ? `/api/v1/clients/${id}` : '/api/v1/clients';

    this.requestSource = CancelToken.source();

    axios[method](url, client, { cancelToken: this.requestSource.token })
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          return this.props.history.push('/clients');
        }
        return this.setState({
          error: data,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        this.setState({ error });
      });
  }

  submitForm() {
    const { client = {} } = this.state;

    client.name = this.nameInput.current.getValue();
    client.short = this.shortInput.current.getValue();
    //client.publicKey = this.publicKeyInput.current.getValue();
    //client.privateKey = this.privateKeyInput.current.getValue();
    client.accountId = this.accountIdInput.current.getValue();
    client.columns = this.columnsInput.current.getValue();
    client.subscriptions = this.subscriptionsInput.current.getValue();

    this.setState({ error: '' });
    this.validateClient(client, (error) => {
      if (error) {
        return this.setState({ error });
      }

      this.submitClient(client);
    });
  }

  renderClient(client) {
    return (
      <form>
        <ErrorPanel error={this.state.error} />
        <table>
          <tbody>
            <tr>
              <th>ID:</th>
              <td>{client._id}</td>
            </tr>
            <tr>
              <th>Name:</th>
              <td>
                <TextInput ref={this.nameInput} defaultValue={client.name} />
              </td>
            </tr>
            <tr>
              <th>Short:</th>
              <td>
                <TextInput
                  ref={this.shortInput}
                  defaultValue={(client.short || '').toUpperCase()}
                />
              </td>
            </tr>
            {/*
            <tr>
              <th>Public Key:</th>
              <td>
                <TextInput
                  ref={this.publicKeyInput}
                  defaultValue={client.publicKey}
                />
              </td>
            </tr>
            <tr>
              <th>Private Key:</th>
              <td>
                <TextInput
                  ref={this.privateKeyInput}
                  defaultValue={client.privateKey}
                />
              </td>
            </tr>
            */}
            <tr>
              <th>Account ID:</th>
              <td>
                <TextInput
                  ref={this.accountIdInput}
                  defaultValue={client.accountId}
                />
              </td>
            </tr>
            <tr>
              <th>Subscriptions:</th>
              <td>
                <TextArea
                  ref={this.subscriptionsInput}
                  style={{ width: '100%', height: '10em' }}
                  defaultValue={JSON.stringify(
                    client.subscriptions || [],
                    null,
                    2
                  )}
                />
              </td>
            </tr>
            <tr>
              <th>Columns:</th>
              <td>
                <TextArea
                  ref={this.columnsInput}
                  defaultValue={JSON.stringify(client.columns, null, 2)}
                  style={{ width: '100%', height: '10em' }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <SubmitButton onClick={this.submitForm.bind(this)} />
              </td>
            </tr>
          </tbody>
        </table>
        <h3>
          Subscription Interval Format (
          <a
            href="https://support.acquia.com/hc/en-us/articles/360004224494-Cron-time-string-format"
            target="_blank"
            rel="noopener noreferrer"
          >
            CRON
          </a>
          ):
        </h3>
        <table>
          <tbody>
            <tr>
              <th>Character</th>
              <th>Descriptor</th>
              <th>Acceptable values</th>
            </tr>
            <tr>
              <td>1</td>
              <td>Minute</td>
              <td>
                <strong>0</strong> to <strong>59</strong>, or <strong>*</strong>{' '}
                (no specific value)
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Hour</td>
              <td>
                <strong>0</strong> to <strong>23</strong>, or <strong>*</strong>{' '}
                for any value. All times UTC.
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Day of the month</td>
              <td>
                <strong>1</strong> to <strong>31</strong>, or <strong>*</strong>{' '}
                (no specific value)
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Month</td>
              <td>
                <strong>1</strong> to <strong>12</strong>, or <strong>*</strong>{' '}
                (no specific value)
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Day of the week</td>
              <td>
                <strong>0</strong> to <strong>7</strong> (0 and 7 both represent
                Sunday), or <strong>*</strong> (no specific value)
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }

  renderReady() {
    const { client = {} } = this.state;
    return (
      <div>
        <h1>Client</h1>
        {this.renderClient(client)}
      </div>
    );
  }

  render() {
    return this[`render${this.state.state}`]();
  }
}

export const Client = withRouter(ClientEditor);

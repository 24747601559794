import React from 'react';
import ErrorPanel from '../components/errorpanel';
import axios from 'axios';

const getErrorMessage = (error) => {
  if (error.response) {
    if (error.response.data) {
      return error.response.data;
    }
  }
  return error.errmsg || error.toString();
};

const sortMailings = (a, b) => {
  if (a.send_finished && b.send_finished) {
    const aDate = new Date(a.send_finished.substr(3)).getTime();
    const bDate = new Date(b.send_finished.substr(3)).getTime();
    return bDate - aDate;
  }
  if (a.send_finished) {
    return -1;
  }
  if (b.send_finished) {
    return 1;
  }
  return 0;
};

const MailingOverview = (mailing) => {
  const { name, recipient_count } = mailing;
  return (
    <tr>
      <th>{name}</th>
      <td>
        {recipient_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </td>
    </tr>
  );
};

class ClientMailingsOverview extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentWillUnmount() {
    if (this.requestSource) {
      this.requestSource.cancel();
    }
  }

  getMailings() {
    const { accountId, privateKey, publicKey } = this.props;
    this.requestSource = axios.CancelToken.source();
    axios
      .get(
        `/api/v1/clients/${accountId}/mailings?privateKey=${privateKey}&publicKey=${publicKey}`,
        { cancelToken: this.requestSource.token }
      )
      .then((response) => {
        if (response.status === 200) {
          const mailings = response.data.filter(
            ({ mailing_type }) => ['s', 'm', 'c'].indexOf(mailing_type) > -1
          );
          return this.setState({
            mailings: mailings.sort(sortMailings),
            error: null,
            loading: false,
          });
        }
        this.setState({
          error: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: getErrorMessage(error),
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.getMailings();
  }

  render() {
    const { short, name } = this.props;
    const mailings = (this.state.mailings || []).slice(0, 5);
    const list =
      mailings.length === 0
        ? [
            <tr key="nomailings">
              <th colSpan={2}>No mailings to report</th>
            </tr>,
          ]
        : mailings.map((mailing, index) => (
            <MailingOverview key={index} {...mailing} />
          ));
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th width="50%">
                {short.toUpperCase()} - {name}
              </th>
              <th width="50%">Recipients</th>
            </tr>
          </thead>
          <tbody>{list}</tbody>
        </table>
      </div>
    );
  }
}

export class Home extends React.Component {
  static caption = 'Home';
  static path = '/';
  static config = {
    exact: true,
  };

  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.getClients();
  }

  componentWillUnmount() {
    if (this.requestSource) {
      console.log('cancel source');
      this.requestSource.cancel();
    }
  }

  getClients() {
    this.requestSource = axios.CancelToken.source();
    axios
      .get('/api/v1/clients', {
        cancelToken: this.requestSource.token,
      })
      .then((response) => {
        if (response.status === 200) {
          const clients = response.data;
          setImmediate(() =>
            this.setState({
              clients,
              error: null,
              loading: false,
            })
          );
        }
      })
      .catch((error) => {
        this.setState({
          error: error.toString(),
          loading: false,
        });
      });
  }

  clients() {
    return this.state.clients || [];
  }

  renderError() {
    return <ErrorPanel error={this.state.error} />;
  }

  renderClients() {
    const clients = this.clients()
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((client, index) => (
        <ClientMailingsOverview
          key={`${client.accountId}_${index}`}
          {...client}
        />
      ));
    return <div>{clients}</div>;
  }

  render() {
    if (this.state.error) {
      return this.renderError();
    }
    const clients = this.clients();
    if (clients.length) {
      return this.renderClients();
    }
    const { user } = this.props;
    return (
      <div>
        <h1>Home</h1>
        <pre>{JSON.stringify(user, null, 2)}</pre>
      </div>
    );
  }
}

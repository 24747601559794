import React from 'react';
import { Route } from 'react-router-dom';

const getPage = page => {
  const {
    caption,
    path,
    paths,
    Component,
    config: staticConfig,
    ...rest
  } = page;
  const config = staticConfig || rest;
  const C = Component || page;
  return {
    path,
    paths,
    caption,
    Component: C,
    config
  };
};

const makePage = ({ caption, path, Component, pageProps, config }) => {
  const PageComponent = props => <Component {...pageProps} {...props} />;
  return <Route key={path} path={path} component={PageComponent} {...config} />;
};

export const Pages = props => {
  const {
    loginCheckDone,
    LoginComponent,
    LayoutComponent,
    loginHandler,
    logoutHandler,
    pages,
    ...pageProps
  } = props;
  const routes = pages.map(page => {
    const { caption, path, paths, Component, config } = getPage(page);
    if (Array.isArray(paths)) {
      return paths.map(path =>
        makePage({
          caption: caption,
          path,
          Component,
          pageProps,
          config
        })
      );
    }
    return makePage({
      caption: caption,
      path,
      Component,
      pageProps,
      config
    });
  });
  return <div className="page">{routes}</div>;
};

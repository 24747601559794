import React, { Component } from 'react';
import Button from './button';

export class SubmitButton extends Component {
  click(e) {
    e.preventDefault();
    if (typeof this.props.onClick === 'function') {
      return this.props.onClick(e);
    }
  }
  render() {
    return <Button onClick={this.click.bind(this)}>Submit</Button>;
  }
}

export default SubmitButton;

import React, { Component } from 'react';
import axios from 'axios';
import TextInput from '../../components/textinput';
import FileUploadInput from '../../components/fileupload';
import FilenameInput from '../../components/filenameinput';
import SubmitButton from '../../components/submitbutton';
import ErrorPanel from '../../components/errorpanel';
import Loading from '../../components/loading';
const fileDownload = require('js-file-download');

export class Merger extends Component {
  static caption = 'Merge Lists';
  static path = '/lists/merge';
  static isAdmin = true;

  constructor() {
    super();
    this.listsInput = React.createRef();
    this.counselorsInput = React.createRef();
    this.rulesInput = React.createRef();
    this.scrubInput = React.createRef();
    this.outputFilename = React.createRef();
    this.accountId = React.createRef();
    this.state = {
      state: 'Input',
      error: ''
    };
  }

  submitForm() {
    const outputFilenameEdit = this.outputFilename.current;
    const outputFilename = outputFilenameEdit.getValue();
    const formData = new FormData();
    const accountId = this.accountId.current.getValue();
    formData.append('accountId', accountId);
    Array.from(document.querySelectorAll('[type=file]')).forEach(input => {
      const name = input.name;
      const files = input.files;
      for (let i = 0; i < files.length; i++) {
        formData.append(`${name}`, files[i]);
      }
    });

    const target = '/api/v1/lists/merge';

    axios
      .post(target, formData, {
        responseType: 'blob'
      })
      .then(response => {
        this.setState({
          error: '',
          state: 'Input'
        });
        return fileDownload(response.data, outputFilename);
      })
      .catch(error => {
        this.setState({
          state: 'Error',
          error: error.toString()
        });
      });
    return this.setState({ error: '', state: 'Requesting' });
  }

  showForm() {
    const { accountId } = this.props.client || {};
    return (
      <form>
        <h1>List Merger</h1>
        <ErrorPanel error={this.state.error} />
        <table>
          <tbody>
            <tr>
              <th>Lists:</th>
              <td>
                <FileUploadInput name="lists" ref={this.listsInput} />
              </td>
            </tr>
            <tr>
              <th>Counselor Map:</th>
              <td>
                <FileUploadInput
                  name="counselors"
                  multiple={false}
                  ref={this.counselorsInput}
                />
              </td>
            </tr>
            <tr>
              <th>Custom Rules:</th>
              <td>
                <FileUploadInput
                  name="rules"
                  multiple={false}
                  accept=".js"
                  ref={this.rulesInput}
                />
              </td>
            </tr>
            <tr>
              <th>Scrub Emails List:</th>
              <td>
                <FileUploadInput
                  name="scrub"
                  multiple={false}
                  ref={this.scrubInput}
                />
              </td>
            </tr>
            <tr>
              <th>Ouput Filename:</th>
              <td>
                <FilenameInput ref={this.outputFilename} default="report.csv" />
              </td>
            </tr>
            <tr>
              <th>Account ID:</th>
              <td>
                <TextInput ref={this.accountId} defaultValue={accountId} />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <SubmitButton onClick={this.submitForm.bind(this)} />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }

  showRequesting() {
    return <Loading />;
  }

  render() {
    switch (this.state.state) {
      case 'Requesting':
        return this.showRequesting();
      default:
        return this.showForm();
    }
  }
}

import React, { Component } from 'react';
import axios from 'axios';
import ErrorPanel from '../../components/errorpanel';
import FileUploadInput from '../../components/fileupload';
import FilenameInput from '../../components/filenameinput';
import SubmitButton from '../../components/submitbutton';
import Loading from '../../components/loading';
const fileDownload = require('js-file-download');

export class GeoFence extends Component {
  static caption = 'Geo Fence Report';
  static path = '/reports/geo-fence';
  static isAdmin = true;

  constructor() {
    super();
    this.listsInput = React.createRef();
    this.geosInput = React.createRef();
    this.outputFilename = React.createRef();
    this.state = {
      state: 'Input',
      error: null
    };
  }

  submitForm() {
    const outputFilenameEdit = this.outputFilename.current;
    const outputFilename = outputFilenameEdit.getValue();
    const formData = new FormData();
    Array.from(document.querySelectorAll('[type=file]')).forEach(input => {
      const name = input.name;
      const files = input.files;
      for (let i = 0; i < files.length; i++) {
        formData.append(`${name}`, files[i]);
      }
    });
    const target = '/api/v1/reports/geo-fence';

    axios
      .post(target, formData, {
        responseType: 'blob'
      })
      .then(response => {
        this.setState({
          error: '',
          state: 'Input'
        });
        return fileDownload(response.data, outputFilename);
      })
      .catch(error => {
        this.setState({
          state: 'Error',
          error: error.toString()
        });
      });
    return this.setState({ error: '', state: 'Requesting' });
  }

  showForm() {
    return (
      <form>
        <h1>Geo Fence Report</h1>
        <ErrorPanel error={this.state.error} />
        <table>
          <tbody>
            <tr>
              <th>Lists:</th>
              <td>
                <FileUploadInput name="lists" ref={this.listsInput} />
              </td>
            </tr>
            <tr>
              <th>Geos:</th>
              <td>
                <FileUploadInput name="geos" ref={this.geosInput} />
              </td>
            </tr>
            <tr>
              <th>Ouput Filename:</th>
              <td>
                <FilenameInput ref={this.outputFilename} default="report.csv" />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <SubmitButton onClick={this.submitForm.bind(this)} />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }

  showRequesting() {
    return <Loading />;
  }

  render() {
    switch (this.state.state) {
      case 'Requesting':
        return this.showRequesting();
      default:
        return this.showForm();
    }
  }
}
